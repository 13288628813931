import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./bocomponent.css";
import { FaBars } from 'react-icons/fa';

const BoSidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showNestedNav, setShowNestedNav] = useState(false);
  const location = useLocation();

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    if (isCollapsed) {
      setShowNestedNav(false); 
    }
  };

  const toggleReportNav = () => {
    setShowNestedNav(!showNestedNav);
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      {!isCollapsed && (
        <nav className='nav-card'>
          <ul>
            <li>
             
            </li>
            <li>
              <Link className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`} to="/dashboard"><i className="fa-solid fa-hourglass-half linkicon"></i>In Progress</Link>
            </li>
            <li>
              <Link className={`nav-item ${location.pathname === '/approved' ? 'active' : ''}`} to="/approved"><i className="fa-solid fa-thumbs-up linkicon"></i>Approved</Link>
            </li>
            <li>
              <Link className={`nav-item ${location.pathname === '/rejected' ? 'active' : ''}`} to="/rejected"><i className="fa-solid fa-thumbs-down linkicon"></i>Rejected</Link>
            </li>
            <li>
              <a onClick={toggleReportNav}>
                <i className="fa-solid fa-table linkicon"></i>Reports
                {showNestedNav && (<i className="fa-solid fa-caret-up linkicon1"></i>)}
                {!showNestedNav && (<i className="fa-solid fa-caret-down linkicon1"></i>)}
              </a>
            </li>
            {showNestedNav && (
              <div className="sidebar-content">
                <nav className='nav-report'>
                  <ul className='report-scroll'>
                    <li>
                      <Link className={`nav-item font-size ${location.pathname === '/actionlog' ? 'active' : ''}`} to="/actionlog">BO Action Log</Link>
                    </li>
                    <li>
                      <Link className={`nav-item font-size ${location.pathname === '/transactionlog' ? 'active' : ''}`} to="/transactionlog">PG Transaction Log</Link>
                    </li>
                    <li>
                      <Link className={`nav-item font-size ${location.pathname === '/contactlog' ? 'active' : ''}`} to="/contactlog">PG Contact Log</Link>
                    </li>
                    <li>
                      <Link className={`nav-item font-size ${location.pathname === '/incompletecases' ? 'active' : ''}`} to="/incompletecases">Incomplete Cases</Link>
                    </li>
                    <li>
                      <Link className={`nav-item font-size ${location.pathname === '/consolidatedlog' ? 'active' : ''}`} to="/consolidatedlog">PG Consolidated Report</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
            <li className='proof-card'>
              <Link className={`nav-item ${location.pathname === '/downloadimage' ? 'active' : ''}`} to="/downloadimage"><i className="fa-solid fa-download linkicon"></i>Download Proof</Link>
            </li>
          </ul>
        </nav>
      )}
      {isCollapsed && (
        <nav>
          <ul>
            <li>
              <FaBars className='toggle-btn1' onClick={toggleCollapse} />
            </li>
            <li>
              <Link className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`} to="/dashboard"><i className="fa-solid fa-hourglass-half linkicon"></i></Link>
            </li>
            <li>
              <Link className={`nav-item ${location.pathname === '/approved' ? 'active' : ''}`} to="/approved"><i className="fa-solid fa-thumbs-up linkicon"></i></Link>
            </li>
            <li>
              <Link className={`nav-item ${location.pathname === '/rejected' ? 'active' : ''}`} to="/rejected"><i className="fa-solid fa-thumbs-down linkicon"></i></Link>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default BoSidebar;
