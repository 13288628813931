import React, { useState } from "react";
import BoHeader from "./Header";
import BoSidebar from "./sideNav";
import { getProofImage } from "../API/getImage";
import { Button, Card } from "react-bootstrap";
import "./bocomponent.css";

const DownloadImage = () => {
  const fetchImage = async (Eia, transactionId) => {
   
    const image = await getProofImage(transactionId, Eia);
    setImage(image);
    // console.log(image, "image");
  };

  const [image, setImage] = useState(null);
  const [Eia, setInput1Value] = useState("");
  const [transactionId, setInput2Value] = useState("");

  const handleInput1Change = (event) => {
    setInput1Value(event.target.value);
  };

  const handleInput2Change = (event) => {
    setInput2Value(event.target.value);
  };

  const handleDownload = (Eia) => {
    // console.log(Eia,"eia in download");
    let imageType="jpeg"
    if(image.startsWith("data:application/pdf;base64,")){
      imageType="pdf"
    }
    else{
      imageType="jpeg"
    }
    const imageName = `${Eia}.${imageType}`;
    // console.log(imageName, "imageName");
    const anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = imageName;
    anchor.click();
  };

  return (
    <div>
      <BoHeader />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <BoSidebar />
        <div className="downloadimage">
          <div className="image-input">
           
            <input
              placeholder="EIA No."
              type="text"
              required
              value={Eia}
              onChange={handleInput1Change}
            />
           
            <input
              type="text"
              required
              placeholder="Transaction ID"
              value={transactionId}
              onChange={handleInput2Change}
            />
          

            <Button
              variant="secondary"
              onClick={() => fetchImage(Eia, transactionId)}
            >
              Get proof
            </Button>
          </div>

          {image && (
            image.startsWith("data:application/pdf;base64,") ? (
              <div className="pdfproof">
                 <embed
                    title="Proof PDF"
                    src={`data:application/pdf;base64,${
                      image.split(",")[1]
                    }`}
                    width="100%"
                    height="100%"
                  />
                   <Button variant="primary" onClick={() => handleDownload(Eia)}>
                  Download Proof
                </Button>
              </div>):(
            <Card className="image-card">
              <Card.Img variant="top" src={image} />
              <Card.Body>
                <Button variant="primary" onClick={() => handleDownload(Eia)}>
                  Download Proof
                </Button>
              </Card.Body>
            </Card>
              )
          )}
        </div>
      </div>
    </div>
  );
};
export default DownloadImage;
