export const formatDate = (date) => {

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = String(date.getDate() ).padStart(2, '0');
    const month = months[date.getMonth()].toUpperCase();
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  
  export const oneYearAgo = (currentDate) => {
    const oneYearAgoDate = new Date(currentDate);
    oneYearAgoDate.setFullYear(currentDate.getFullYear() - 1);
    return oneYearAgoDate;
  };

  export const oneMonthAgo = (currentDate) => {
    const oneMonthAgoDate = new Date(currentDate);
    oneMonthAgoDate.setMonth(currentDate.getMonth() - 1);
    return oneMonthAgoDate;
  };