import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { VirtualScroller } from "primereact/virtualscroller";
import VirtualScroll from "react-dynamic-virtual-scroll";
import "./style.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import logo from "../Login/logo.svg";
import { MDBDataTable } from "mdbreact";
import scroll from "scroll";
import { Await } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import POCTableData from "../InsurerDetails/POCTableData";
import DatePicker from "react-date-picker";
import Policy from "../Policy/policyDetails";
import Loading from "../Error/Loading";
import { RotateSpinner } from "react-spinners-kit";
import CustomizedDialogs from "../Error/DailogBox";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import Navbar from "../Header/Navbar";
import Header from "../Header/Header";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const columns = [
  { id: "Sl_No", label: "Sl No", minWidth: 100 },
  { id: "eiaNo", label: "eiA No", minWidth: 150 },
  { id: "requestId", label: "SR No", minWidth: 150 },
  { id: "email", label: "Email", minWidth: 200 },
  { id: "supportCategoryCode", label: "Support Category Code", minWidth: 150 },
  {
    id: "supportCategoryDesc",
    label: "Support Category Description",
    minWidth: 200,
  },
  { id: "supportUserQuery", label: "Support User Query", minWidth: 200 },
  { id: "createdDt", label: "Created Date", minWidth: 100 },
  { id: "updatedDt", label: "Updated Date", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 200 },
];

function SupportPage(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const currentDate = new Date();

  // Format the date components individually
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = currentDate.getDate().toString().padStart(2, "0");
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const milliseconds = currentDate
    .getMilliseconds()
    .toString()
    .padStart(3, "0");

  // Combine the components into the desired format
  const timeStamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}00`;

  const [showTable, setShowTable] = useState(false);

  const toggleTable = () => {
    setShowTable(!showTable);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [errorMessages, setErrorMessages] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [value, setValue] = useState("");
  const [pass, setPass] = useState("");
  let isShowPOCList = false;
  const [list, setList] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [data, setData] = React.useState([]);
  const [openDailog, setOpenDailog] = useState(false);

  const [transactionId, setTransactionId] = useState(uuidv4());

  const generateNewTransactionId = () => {
    const newTransactionId = uuidv4();
    setTransactionId(newTransactionId);
  };
  // console.log(transactionId,'tttttttttttttttttttttttttttt')

  // New unique id
  //  const unique_id = uuid();

  //  // Get first 8 characters using slice
  //  const small_id = unique_id.slice(0, 8);

  const [insurerCode, setInsurerCode] = useState("");
  const [PolicyNo, setPolicyNo] = useState("");
  const [policyType, setPolicyType] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");
  const [srNo, setSrNo] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false);

  const [imageBase64, setimageBase64] = useState("");
  const [responseMessage, setresponseMessage] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [fileURL, setfileURL] = useState("");

  const date = new Date();
  const formattedDate = date.toLocaleDateString("en-GB");

  // const changeInsurer = (event) => {
  //   setInsurerCode(event.target.value);
  // };

  // const changeName = (event) => {
  //   setName(event.target.value);
  // };
  // const changeFromDate = (event) => {
  //   setFromDate(event.target.value);
  // };

  // const changeToDate = (event) => {
  //   setToDate(event.target.value);
  // };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const handleChange2 = async (event) => {
  //   event.preventDefault();

  //   // ... (your existing form validation logic)

  //   try {
  //     // Call the fetchData function here
  //     await fetchData();

  //     // Set list to true after the API call is successful
  //     setList(true);
  //   } catch (error) {
  //     // Handle errors from the fetchData function
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const clearState = () => {
    setInsurerCode("");
    setPolicyNo("");
    setPolicyType("");
    setFromDate("");
    setToDate("");
  };

  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Months are zero-based
    const year = dateObject.getFullYear();

    // Pad single-digit day and month with a leading zero
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const BaseUrl = process.env.REACT_APP_API_URL;

  const handleButtonClick = async (e) => {
    e ? e.preventDefault() : setLoading(true);
    generateNewTransactionId();

    // console.log("button clicked");
    // e.preventDefault()
    // const formattedFromDate = formatDate(fromDate);

    // const formattedToDate = formatDate(toDate);
    if (
      (PolicyNo === "") &
      (policyType === "") &
      (fromDate === "") &
      (toDate === "") &
      (srNo === "")
    ) {
      alert("Please fill in all mandatory fields");
      setLoading(false);
      return;
    }

    const formattedFromDate = new Date(fromDate).toLocaleDateString("en-GB"); // Assumes 'toDate' is in the format "YYYY-MM-DD"

    const formattedToDate = new Date(toDate).toLocaleDateString("en-GB"); // Assumes 'toDate' is in the format "YYYY-MM-DD"

    const EndPoint = "/riInsurerBulkService/v1/ListSupport";

    try {
      const response = await fetch(`${BaseUrl}${EndPoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          txnId: transactionId,
          timestamp: "2023-11-20T16:33:25.387994200",
          Source: {
            appType: "MOBILE",
            osVersion: "11",
            deviceId: "",
            deviceName: "Mymobile 52",
            deviceOS: "Android",
            appVersion: "2.0",
            clientIp: "0.0.0.0",
            origin: "",
          },
          Customer: {
            eiaNo: PolicyNo,
            requestId: srNo,
            fromDate: formattedFromDate,
            toDate: formattedToDate,
            status: policyType,
          },
        }),
      });
      // console.log(
      //   insurerCode,
      //   policyType,
      //   formattedFromDate,
      //   formattedToDate,
      //   transactionStatus
      // );
      if (response.ok) {
        setLoading(false);
        const jsonResponse = await response.json();
        const transactions = jsonResponse.Support;
        for (let i = 0; i < transactions.length; i++) {
          transactions[i] = { Sl_No: i + 1, ...transactions[i] };
        }
        setResponseData(transactions);
        setShowTable(true);
        // console.log(transactions, "Suport/////////////////////");
      } else {
        // Handle errors
        setLoading(false);
        const errorResponse = await responseData.json();
        alert(`Error: ${errorResponse.displayMessage}`);
      }
    } catch (error) {
      // Handle network errors or any other exceptions
      setLoading(false);
      console.error("Error:", error.message);
    }
  };

  const handleCloseTicket = async (e) => {
    setLoading(true);
    e.preventDefault();
    generateNewTransactionId();
    let userId = sessionStorage.getItem("userId");
    const closeTicketEndpoint =
      "/riInsurerBulkService/v1/setSupportRequestDetails";
    // console.log({
    //   "supportRequestNo": currentTicket.requestId,
    //   "eiaNo": currentTicket.eiaNo,
    //   "status": currentTicket.status,
    //   "userId": userId,
    //   "attachmentBase64": imageBase64

    // })
    if (imageBase64 === "") {
      seterrorMessage("please upload document");
      setLoading(false);
      return;
    }

    try {
      const closeTicketResponse = await fetch(
        `${BaseUrl}${closeTicketEndpoint}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            txnId: transactionId,
            timestamp: "2023-11-20T16:33:25.387994200",
            Source: {
              appType: "MOBILE",
              osVersion: "11",
              deviceId: "",
              deviceName: "Mymobile 52",
              deviceOS: "Android",
              appVersion: "2.0",
              clientIp: "0.0.0.0",
              origin: "",
            },
            Customer: {
              supportRequestNo: currentTicket.requestId,
              eiaNo: currentTicket.eiaNo,
              status: currentTicket.status,
              userId: userId,
              attachmentBase64: imageBase64,
              status: "Completed",
            },
          }),
        }
      );
      if (await closeTicketResponse.ok) {
        let res = await closeTicketResponse.json();
        setLoading(false);
        handleClose();
        setimageBase64("");
        res.errorDescription
          ? setresponseMessage({
              message: res.errorDescription,
              color: "error",
            })
          : setresponseMessage({
              message: res.mApiErrorDescription,
              color: "success",
            });
        setOpenDailog(true);
        handleButtonClick();
      } else {
        setLoading(false);
        // Handle errors

        const errorResponse = await closeTicketResponse.json();
        alert(`Error: ${errorResponse.displayMessage}`);
      }
    } catch (error) {
      // Handle network errors or any other exceptions
      setLoading(false);
      handleClose();
      alert("Error:", error.message);
    }
  };

  const convertToBase64 = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.readAsDataURL(file);
    setfileURL(file.name);

    reader.onload = async () => {
      await setimageBase64(reader.result);
    };
    seterrorMessage("");
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const formData = {
  //         txnId: '9e5adbbe-39ae-72e8-a8d7-0289147bf002',
  //         timestamp: '2023-11-20T16:33:25.387994200',
  //         Source: {
  //           appType: 'WEB',
  //           osVersion: '11',
  //           deviceId: '',
  //           deviceName: 'Windows108',
  //           deviceOS: 'Windows',
  //           appVersion: '1.0',
  //           clientIp: '0.0.0.0',
  //           origin: 'RI',
  //         },
  //         Customer: {
  //           insurerCode: insurerCode,
  //           policyType: policyType,
  //           fromDate: fromDate,
  //           toDate: toDate,
  //         },
  //       };

  //       const response = await fetch('https://uatcamsrep.bimacentral.in/riInsurerBulkService/v1/SFTPUploadList', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(formData),
  //       });

  //       if (response.ok) {
  //         const jsonResponse = await response.json();
  //         setResponseData(jsonResponse.SftpUploadList);
  //         console.log('Response Data:', jsonResponse);
  //       } else {
  //         // Handle errors
  //         console.error('Error:', response.status, response.statusText);
  //       }
  //     } catch (error) {
  //       // Handle network errors
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  function handleReset(e) {
    e.preventDefault();
    // console.log(insurerCode, policyType, transactionStatus, fromDate, toDate);
    // clearing the values
    setInsurerCode("");
    setPolicyNo("");
    setPolicyType("");
    setTransactionStatus("");
    setFromDate("");
    setToDate("");
    setShowTable(false);
    setLoading(false);
    setSrNo("");
  }

  // function validateForm() {
  //   // Check if the First Name is an Empty string or not.

  //   if (insurerCode.length == 0) {
  //     alert('Invalid Form, Insurer Code can not be empty')
  //     return
  //   }

  //   // Check if the Email is an Empty string or not.

  //   if (name.length == 0) {
  //     alert('Invalid Form, Name can not be empty')
  //     return
  //   }

  //   if (fromDate.length == 0) {
  //     alert('Invalid Form, From Date can not be empty')
  //     return
  //   }

  //   if (toDate.length == 0) {
  //     alert('Invalid Form, To Date can not be empty')
  //     return
  //   }

  //   // if all the conditions are valid, this means that the form is valid

  //   // alert('Form is valid')
  //   // navigate("/menudisplaypage"); // Omit optional second argument
  //       handleChange2()

  // }

  const handleChange = (event, setDate) => {
    const rawDate = event.target.value;
    const formattedDate = formatDate(rawDate);
    setDate(formattedDate);
  };
  const handleExportClick = () => {
    // Export response data as Excel
    exportAsExcel(responseData);
    // console.log("Export Success");
  };

  const exportAsExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `Support report From ${fromDate} to ${toDate}.xlsx`);
  };

  const navigate = useNavigate();

  const backbutton = () => {
    navigate("/menudisplaypage");
  };
  // const ActionBtn = ()=>{
  //   setOpenDailog(!openDailog)
  // }

  const inputNumeric = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9]+$/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value.slice(0, 13).toUpperCase());
    }
  };

  const [open, setOpen] = React.useState(false);
  const [currentTicket, setcurrentTicket] = React.useState("");

  const handleClickOpen = (currentTicket) => {
    setOpen(true);
    setcurrentTicket(currentTicket);
  };
  const handleClose = () => {
    setOpen(false);
    setcurrentTicket("");
    seterrorMessage("");
    setfileURL("");
    setimageBase64("");
  };

  const cancelBtn = () => {
    setOpen(false);
    setcurrentTicket("");
    seterrorMessage("");
    setfileURL("");
    setimageBase64("");
  };

  const closeDialog = () => {
    setOpenDailog(false);
  };

  const todayDate = new Date().toISOString().split("T")[0];

  return (
    <div>
      <Snackbar
        open={openDailog}
        autoHideDuration={3000}
        onClose={closeDialog}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity={responseMessage.color}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {responseMessage.message}
        </Alert>
      </Snackbar>

      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <div className="dailog-container">
            <div className="dailog-card-button1">
              <button className="cancel-button" onClick={cancelBtn}>
                <CancelIcon />
              </button>
            </div>
            <div className="dailog-card">
              <h5 className="dailog-head">eia No </h5>
              <p className="dailog-paragraph">: {currentTicket.eiaNo}</p>
            </div>
            <div className="dailog-card">
              <h5 className="dailog-head">SR No </h5>
              <p className="dailog-paragraph0">: {currentTicket.requestId}</p>
            </div>
            <div className="dailog-card">
              <h5 className="dailog-head">Raised Date </h5>
              <p className="dailog-paragraph1">: {currentTicket.createdDt}</p>
            </div>
            <div className="dailog-card">
              <h5 className="dailog-head">Query Description </h5>
              <p className="dailog-paragraph2">
                : {currentTicket.supportUserQuery}
              </p>
            </div>
            <div className="dailog-card">
              <h5 className="dailog-head">Current Status </h5>
              <p className="dailog-paragraph3">: {currentTicket.status}</p>
            </div>
            <div className="dailog-card">
              <h5 className="dailog-head4">Upload document </h5>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  onChange={(event) => convertToBase64(event)}
                />
              </Button>
            </div>
            <p
              style={{
                textAlign: "center",
                color: fileURL !== "" ? "black" : "red",
                marginTop: "10px",
              }}
            >
              {fileURL !== "" ? fileURL : errorMessage}
            </p>
            <div className="dailog-card-button">
              <button
                className="close-button"
                onClick={(event) => handleCloseTicket(event)}
              >
                Close Ticket
              </button>
            </div>
          </div>
        </BootstrapDialog>
      </React.Fragment>
      <Header />
      <div className="MenuPage">
        <Navbar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      </div>
      <div className="app-1">
        <div>
          <h1 className="headingr">Support</h1>
        </div>

        <form method="post" className="form-card">
          {/* Add your text fields and button for user input */}
          <div className="input-container">
            <table1>
              <tr>
                <td>eIA no</td>
                <td>
                  <input
                    type="text"
                    name="PolicyNo"
                    value={PolicyNo}
                    required
                    onChange={(e) =>
                      inputNumeric(e, (value) => {
                        setPolicyNo(value);
                      })
                    }
                    className="inputbox td"
                  />
                </td>
              </tr>

              {/* <tr><td>InsuranceCode</td><td><input type="text" name="insurerCode" value={insurerCode} required onChange={(e) => setInsurerCode(e.target.value)} class="inputbox" /></td></tr> */}

              <tr>
                <td>SR No</td>
                {/* <select className="select-tagd" value={policyType}  onChange={(e) => setPolicyType(e.target.value)}>
        <option value="">Select</option>
          <option value="Health">Health</option>
          <option value="Motor">Motor</option>
          <option value="Life">Life</option>
        </select> */}
 
 
                <td>
                  <input
                    type="text"
                    name="srNo"
                    value={srNo}
                    onChange={(e) => setSrNo(e.target.value)}
                    className="inputbox td"
                    required
                  />
                </td>
              </tr>

              <tr>
                <td>From Date</td>
                <td>
                  <input
                    type="date"
                    name="fromDate"
                    value={fromDate}
                    className="inputbox td"
                    max={todayDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    required
                  />
                </td>
              </tr>

              <tr>
                <td>To Date</td>
                <td>
                  <input
                    type="date"
                    name="toDate"
                    value={toDate}
                    className="inputbox td"
                    max={todayDate}
                    onChange={(e) => setToDate(e.target.value)}
                    required
                  />
                </td>
              </tr>

              <tr>
                <td>Status </td>
                <select
                  className="select-tagt td"
                  value={policyType}
                  onChange={(e) => setPolicyType(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                </select>
              </tr>

              {/* <td className="note">Note: (Please fill either Eia No OR SR No OR fromDate, toDate, Status )</td> */}
              {/* <tr><td>Transaction Status</td><td><input type="text" name="transactionStatus" value={transactionStatus} onChange={(e) => setTransactionStatus(e.target.value)} class="inputbox"/></td></tr> */}

              <p className="note">
                note:(Mandatory fields Either Eia No OR SR No OR From Date, To
                Date, Status)
              </p>

              <tr>
                <td>
                  <button onClick={backbutton} className="firstBtn">
                    BACK
                  </button>
                </td>
                <td>
                  <button onClick={handleReset} className="firstBtn">
                    RESET
                  </button>
                  <button onClick={handleButtonClick} className="firstBtn">
                    SUBMIT
                  </button>
                  <div className="App"></div>
                </td>
              </tr>
            </table1>
          </div>
          <div className="loading">
            <RotateSpinner size={70} color="#f99117" loading={loading} />
          </div>
        </form>
        {/* {openDailog?<CustomizedDialogs />:""} */}

        <div className="col-12">
          <div className="item-insurer">
            <div className="tableContainer-insurer">
              {showTable && (
                <div className="">
                  <button onClick={handleExportClick} className="export-button">
                    Export as Excel
                  </button>
                  {/* <Button variant="outlined">Outlined</Button> */}
                  <div>
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {responseData
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => {
                                // debugger;
                                // console.log(row, index);
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.code}
                                  >
                                    {columns.map((column) => {
                                      const value = row[column.id];
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                        >
                                          {column.id === "action" &&
                                          row.status === "Pending" ? (
                                            <Button
                                              variant="contained"
                                              onClick={() =>
                                                handleClickOpen(row)
                                              }
                                            >
                                              Close Ticket
                                            </Button>
                                          ) : (
                                            value
                                          )}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={responseData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportPage;
