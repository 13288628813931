import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./ReportStyle.css";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";



export default function AlertDialog(flag) {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate()
  const flagDailog = flag.flag;

  useEffect(() => {
    setOpen(flagDailog);
  }, [flag]);

  const handleReset = ()=>{
    navigate("/resetpassword")
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Grid className="dailog-raduis">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dailog-raduis"
       
      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Use Google's location service?"} */}
        </DialogTitle>
        <DialogContent className="dialog-card">
          <DialogContentText
            className="dilog-text"
            id="alert-dialog-description"
          >
            Your password has been expired..!!!
            <br />
            To Reset Password
          </DialogContentText>
          <Button className="reset-click" onClick={handleReset}>
            Click 
          </Button>
     
        </DialogContent>
        {/* <DialogActions>
          <Button className="dialog-btn" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions> */}
      </Dialog>
      </Grid>
    </React.Fragment>
  );
}
