// Header.js

import React from "react";
import { Link } from "react-router-dom"; // If you're using React Router
import logo from "../Login/logo.svg";
import PersonIcon from '@mui/icons-material/Person';

const Header = () => {
  const userId = sessionStorage.getItem("userId");

  return (
    <div className="header">
      <div className="logo-bima">
        <Link to="/">
          <img
            alt="img"
            src={logo}
            className="clickable-image"
            style={{ width: "150px", height: "60px" }} // Adjust the width and height as needed
          />
        </Link>
      </div>

      <div className="profile-card">
        <PersonIcon fontSize="medium" style={{marginTop:"8px",marginRight:"3px",color:"#f99117"}} />
        <h1 style={{fontSize:"16px",marginTop:"12px"}}> USERID:  <a style={{color:"#f99117"}}>{userId}</a></h1>
      </div>
    </div>
  );
};

export default Header;
