import React, { useState } from "react";
import "./style.css";
import Header from "../Header/Header";
import Navbar from "../Header/Navbar";
import { ROUTER_URL, LABEL_NAME } from "../Common/constant";
import Modal from "@mui/material/Modal";
import { Buffer } from "buffer/";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { RotateSpinner } from "react-spinners-kit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";

function PolicyDetailsBulk() {
  const [lotValue, setLotValue] = useState("");
  const [policyValue, setPolicyValue] = useState("");
  const [lotNo, setLotNoValue] = useState("");
  const [lotDate, setLotDateValue] = useState("");
  const [dataFileName, setDataFileName] = useState(null);
  const [fileData, setFileData] = useState();
  const userId = sessionStorage.getItem("userId");
  const [open, setOpen] = useState(false);
  const [success, setSucsess] = useState();
  const [showTable, setShowTable] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [fileName, setFileName] = useState("");
  const [uploadType, setUploadType] = useState("");
  const [fileError, setFileError] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFileUpload = (e) => {
    setDataFileName(e.target.value);
    setFileError("");
    const file = e.target.files[0];
    // console.log(file.size, "fileSize----->");
    if (uploadType === "bulk") {
      if (file.size < 50000000) {
        let fileName = file.name;
        setFileName(fileName);
        const formdata = new FormData();

        formdata.append("file", file);
        // console.log(file, "FILe------->");

        setFileData(formdata);
        console.log("Correct");
      } else {
        setFileError("Upload Less than 50MB for Bulk");
        setDataFileName("");
        setFileData("");
      }
    } else if (uploadType === "single") {
      if (file.size < 1000000) {
        let fileName = file.name;
        setFileName(fileName);
        const formdata = new FormData();

        formdata.append("file", file);
        // console.log(file, "FILe------->");

        setFileData(formdata);
      } else {
        setFileError("Upload Less than 1MB for Individual");
        setDataFileName("");
        setFileData("");
      }
    }
  };

  const INSURER_COLUMNS = [
    { id: "Sl_No", label: "S.No", minWidth: 50 },
    { id: "fileId", label: "File ID", minWidth: 100 },
    // { id: 'uploadRefNo', label: 'Upload Ref No', minWidth: 100 },
    // { id: 'userRefId', label: 'User Ref Id', minWidth: 100 },
    { id: "amc", label: "AMC", minWidth: 100 },
    { id: "eiaNo", label: "eIAno", minWidth: 100 },

    { id: "policyNo", label: "PolicyNo", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "errorDiscription", label: "Description", minWidth: 100 },
    // {
    //   id: "remarks",
    //   label: "Remarks",
    //   minWidth: 170,
    //   align: "right",
    //   format: (value) => value.toLocaleString('en-US'),
    //   format: (value) => value.toFixed(2),
    // },
  ];

  const columns = INSURER_COLUMNS;

  const importSubmit = async (e) => {
    e.preventDefault();

    if (!policyValue || (!lotNo && !lotDate) || !dataFileName || !uploadType) {
      alert("Please fill in all mandatory fields");
      return;
    }

    setLoading(true);

    const data = JSON.stringify({
      txnId: "9e5adbbe-39ae-72e8-a8d7-0289147bf002",
      timestamp: "2023-11-20T16:33:25.387994200",
      source: {
        appType: "WEB",
        osVersion: "11",
        deviceId: "",
        deviceName: "Windows108",
        deviceOS: "Windows",
        appVersion: "1.0",
        clientIp: "0.0.0.0",
        origin: "RI",
      },
      uploadpolicy: {
        policyType: policyValue,
        fileName: fileName,
        filePath: "",
        lotNo: lotNo,
        lotDate: "25-MAR-2024",
        userCode: userId,
        uploadType: uploadType,
      },
    });

    let objJsonB64 = Buffer.from(data).toString("base64");
    const BaseUrl = process.env.REACT_APP_API_URL

    // const EndPoint = "/riInsurerBulkService/v1/getReconLogDetails"

    try {
      const response = await fetch(
        `${BaseUrl}/riPolicyBulkUpload/v1/documentUpload?data=${objJsonB64}`,
        {
          method: "POST",
          body: fileData,
        }
      );

      if (response.ok) {
        if (uploadType == "single") {
          let jsonResponse = await response.json();
          jsonResponse = jsonResponse.response;

          //conversion start here
          let convertedData = [];
          jsonResponse.forEach((element, index) => {
            let temp = {};
            // temp["Sl No"] = "0";
            columns.forEach((column) => {
              if (column.id === "Sl_No") {
                temp[column.label] = index + 1;
              } else {
                temp[column.label] = element[column.id];
              }
            });
            convertedData.push(temp);
            temp = {};
          });

          setShowContent(false);
          setResponseData(convertedData);
          setShowTable(true);
        } else if (uploadType == "bulk") {
          const jsonResponse = await response.text(); //.json();
          setSucsess(jsonResponse);
          setOpen(true);
        }
        setLoading(false);
      } else {
        // Handle errors
        const errorResponse = await response.json();
        alert(`Error: ${errorResponse.displayMessage}`);
      }
    } catch (error) {
      // Handle network errors or any other exceptions
      console.error("Error:", error.message);
    }
  };
  const lotNoFunc = (e) => {
    setLotValue(e.target.value);
  };

  const lotNoFunc1 = (e) => {
    setLotValue(e.target.value);
  };

  const reset = () => {
    setLotDateValue("");
    setLotNoValue("");
    setPolicyValue("");
    setDataFileName("");
    setLoading(false);
    setUploadType("");
    setUploadType("");
  };
  const openFunc = () => {
    setOpen(false);
    setLotDateValue("");
    setLotNoValue("");
    setPolicyValue("");
    setDataFileName("");
    setUploadType("");
    setUploadType("");
    setLoading(false);
  };

  const TableBtn = () => {
    setShowContent(true);
    setLotDateValue("");
    setLotNoValue("");
    setPolicyValue("");
    setDataFileName("");
    setUploadType("");
  };

  const navigate = useNavigate();

  const backButton = () => {
    navigate("/menudisplaypage");
  };

  // console.log(fileError, "ERROR");

  return (
    <div className="bulk-card">
      <Header />
      <div className="MenuPage">
        <Navbar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      </div>
      {/* <div className="bulk-para"><p>import Policy Details</p></div> */}
      {/* <p className="paragraph">import Policy Details</p> */}
      {showContent ? (
        <div className="card-container">
          <div>
            <h1 className="heading1-bulk policy-heading">
              {ROUTER_URL.POLICY_IMPORT}
            </h1>
          </div>
          <Modal open={open}>
            <div className="model-card">
              <div className="open-card">
                <CheckCircleOutlineIcon sx={{ fontSize: 40 }} color="success" />
                <p className="model-para">upload successful !</p>
                <button className="model-btn" onClick={openFunc}>
                  Close
                </button>
              </div>
            </div>
          </Modal>
          <div className="card-div">
            <div className="label-card">
              <label className="label-bulk">Policy Data Type *</label>
            </div>

            <div className="policydata-select">
              <select
                className="policydata-select-select"
                value={policyValue}
                onChange={(e) => setPolicyValue(e.target.value)}
                required
              >
                <option value="">Select</option>
                <option value="Health">Health</option>
                <option value="Motor">Motor</option>
                <option value="Life">Life</option>
              </select>
            </div>
          </div>
          <div className="card-div">
            <div className="label-card">
              <label className="label-bulk">Select the Option *</label>
            </div>

            <div className="card-div1">
              <div className="radio-btn">
                <input
                  type="radio"
                  name="value"
                  value="LotNo"
                  onClick={lotNoFunc}
                />
                <label className="label-bulk-radio">Lot No</label>
              </div>
              <div className="radio-btn">
                <input
                  type="radio"
                  name="value"
                  value="LotDate"
                  onClick={lotNoFunc1}
                />
                <label className="label-bulk-radio">Lot Date </label>
              </div>
            </div>
          </div>
          {lotValue === "LotNo" ? (
            <div className="input-card">
              <input
                type="text"
                className="input-text"
                value={lotNo}
                onChange={(e) => setLotNoValue(e.target.value)}
                placeholder="ex:123456..."
              />
            </div>
          ) : (
            <div className="input-card">
              <input
                type="date"
                className="input-text"
                value={lotDate}
                onChange={(e) => setLotDateValue(e.target.value)}
              />
            </div>
          )}

          {/* <div className="input-card">
          <input type="text" className="input-text" />
        </div>

        <div className="input-card">
          <input type="date" className="input-text" />
        </div> */}
          {/* <div className="card-div">
          {lotValue === "LotNo" ? (
            <div>
              <label className="label">Lot No *</label>

              <div className="input-date-card">
                <input
                  type="text"
                  className="date"
                  value={lotNo}
                  onChange={(e) => setLotNoValue(e.target.value)}
                  placeholder="ex:123456..."
                />
              </div>
            </div>
          ) : (
            <div>
              <label className="label">Lot Date *</label>
              <div className="input-date-card">
                <input
                  type="date"
                  className="date"
                  value={lotDate}
                  onChange={(e) => setLotDateValue(e.target.value)}
                />
              </div>
            </div>
          )}

          <div className="card-div1"></div>
        </div> */}
          {/* <div className="card-div">
          <div className="label-card">
            <label className="label-bulk">Model Type</label>
          </div>

          <div className="policydata-select">
            <select className="policydata-select">
              <option value="Model1">Model1</option>
              <option value="Model2">Model2</option>
              <option value="Model3">Model3</option>
              <option value="Model4">Model4</option>
            </select>
          </div>
        </div> */}
          <div className="card-div">
            <div className="label-card">
              <label className="label-bulk">Upload File Type *</label>
            </div>

            <div className="policydata-select">
              <select
                className="policydata-select-select"
                value={uploadType}
                onChange={(e) => setUploadType(e.target.value)}
                required
              >
                <option value="">Select</option>
                <option value="single">Individual</option>
                <option value="bulk">Bulk</option>
              </select>
            </div>
          </div>
          <div className="card-div">
            <div className="label-card">
              <label className="label-bulk">XML File *</label>
            </div>

            <div className="policydata-select">
              <div>
                <input
                  type="file"
                  id="myFile"
                  name="filename"
                  accept=".xml"
                  onChange={handleFileUpload}
                  value={dataFileName}
                  disabled={uploadType ? false : true}
                />
                <div>
                  {(uploadType == "single" || uploadType == "bulk") &&
                  fileError !== "" ? (
                    <p className="error-message">{fileError}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <p className="note-para-label">(.XML)</p>
            </div>
          </div>

          {/* <div className="card-div">
            <div className="label-card">
              <label className="label-bulk">Data File Name *</label>
            </div>

            <div className="policydata-select">
              <input
                type="file"
                id="myFile"
                name="filename"
                accept=".zip,.rar,.7zip"
              />
              <p className="note-para-label">(.ZIP)</p>
            </div>
          </div> */}
          {/* <div className="card-div">
            <div className="label-card">
              <label className="label-bulk">image File</label>
            </div>

            <div className="policydata-select">
              <input
                type="file"
                id="myFile"
                name="filename"
                accept=".zip,.rar,.7zip"
              />
              <p className="note-para-label">(.ZIP)</p>
            </div>
          </div> */}
          <div className="card-div">
            <div className="label-card">
              {/* <label className="label-bulk">image File</label> */}
            </div>
          </div>
          <p className="note-para"> Note: {LABEL_NAME.POLICY_IMPORT_NOTE}</p>
          <div className="buttons">
            <button className="btn_insurer_bulk" onClick={importSubmit}>
              Upload File
            </button>
            <button className="btn_insurer_bulk" onClick={reset}>
              RESET
            </button>
            <button className="btn_insurer_bulk" onClick={backButton}>
              Back
            </button>
          </div>
          <div className="loading">
            <RotateSpinner size={70} color="#f99117" loading={loading} />
          </div>
        </div>
      ) : (
        <div className="col-12">
          <div className="item-insurer">
            <div className="tableContainer-insurer">
              {showTable && (
                <div className="">
                  <div className="table-card">
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {responseData
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.code}
                                  >
                                    {columns.map((column) => {
                                      const value = row[column.label];
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                        >
                                          {/* {column.format &&
                                        typeof value === "number"
                                          ? column.format(value)
                                          : value} */}
                                          {value}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={responseData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                    <div style={{ textAlign: "right" }}>
                      <button onClick={TableBtn}>BACK</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PolicyDetailsBulk;
