// import React, { useState, useEffect } from "react";
// import { BrowserRouter, useNavigate } from 'react-router-dom';
// // import Table from "../Table";
// import "./style.css";
// import * as XLSX from "xlsx";
// import { v4 as uuid } from "uuid";
// import { RotateSpinner } from "react-spinners-kit";
// import Select from "react-select";
// import Header from "../Header/Header";

import Poc from "../Common/POC";

// import Navbar from "../Header/Navbar";

// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
// import ActionButton from "../Common/ActionButton";
// import { ROUTER_URL, LABEL_NAME } from "../Common/constant";

// const columns = [
//   { id: 'Sl_No', label: 'Sl No', minWidth: 50 },
//   { id: 'policyNo', label: 'Policy No', minWidth: 100 },
//   { id: 'eiaNo', label: 'EIA No', minWidth: 100 },
//   { id: 'reqUploadRefNo', label: 'Request Upload Ref No', minWidth: 100 },
//   { id: 'resUploadRefNo', label: 'Response Upload Ref No', minWidth: 100 },
//   { id: 'errUploadRefNo', label: 'Error Upload Ref No', minWidth: 100 },
//   { id: 'userRefId', label: 'User Ref Id', minWidth: 100 },
//   { id: 'insurerCode', label: 'Insurer Code', minWidth: 100 },
//   { id: 'requestFileName', label: 'Request File Name', minWidth: 100 },
//   { id: 'uploadReqStatus', label: 'Upload Req Status', minWidth: 100 },
//   { id: 'uploadReqDate', label: 'Upload Req Date', minWidth: 100 },

//   { id: 'responseFileName', label: 'Response File Name', minWidth: 100 },
//   { id: 'uploadResStatus', label: 'Upload Res Status', minWidth: 100 },
//   { id: 'uploadResDate', label: 'Upload Res Date', minWidth: 100 },
//   { id: 'errFileName', label: 'Error File Name', minWidth: 100 },
//   { id: 'errCode', label: 'Error Code', minWidth: 100 },
//   { id: 'errDescription', label: 'Error Description', minWidth: 100 },
//   { id: 'processedStatus', label: 'Processed Status', minWidth: 100 },
//   { id: 'processedDate', label: 'Processed Date', minWidth: 100 },
//   { id: 'rejectionReason', label: 'Rejection Reason', minWidth: 100 },
//   { id: 'trxnStatus', label: 'Transaction Status', minWidth: 100 },
//   {
//     id: 'remarks',
//     label: 'Remarks',
//     minWidth: 100,
//     align: 'right',
//     // format: (value) => value.toLocaleString('en-US'),
//     // format: (value) => value.toFixed(2),
//   }
// ];



// function POCList(props) {
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(5);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };
//   const currentDate = new Date();

//   // Format the date components individually
//   const year = currentDate.getFullYear();
//   const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
//   const day = currentDate.getDate().toString().padStart(2, '0');
//   const hours = currentDate.getHours().toString().padStart(2, '0');
//   const minutes = currentDate.getMinutes().toString().padStart(2, '0');
//   const seconds = currentDate.getSeconds().toString().padStart(2, '0');
//   const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0');

//   // Combine the components into the desired format
//   const timeStamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}00`;

//   const [showTable, setShowTable] = useState(false);

//   const toggleTable = () => {
//     setShowTable(!showTable);
//   };

//   const [loading, setLoading] = useState(false);
//   const [responseData, setResponseData] = useState([]);
//   const [transactionStatus, setTransactionStatus] = useState('');




//   // New unique id
//   const unique_id = uuid();

//   // Get first 8 characters using slice
//   const small_id = unique_id.slice(0, 8);




//   const [insurerCode, setInsurerCode] = useState("");
//   const [insurerName, setInsurerName] = useState("");
//   const [refNum, setRefNum] = useState("");

//   const [fromDate, setFromDate] = useState("");
//   const [toDate, setToDate] = useState("");


//   const [insurerData, setInsurerData] = useState([]);
//   const [selectedInsurer, setSelectedInsurer] = useState('Select');

//   const date = new Date();
//   const formattedDate = date.toLocaleDateString('en-GB');

//   function formatDate(dateString) {
//     const dateObject = new Date(dateString);
//     const day = dateObject.getDate();
//     const month = dateObject.getMonth() + 1; // Months are zero-based
//     const year = dateObject.getFullYear();

//     // Pad single-digit day and month with a leading zero
//     const formattedDay = day < 10 ? `0${day}` : day;
//     const formattedMonth = month < 10 ? `0${month}` : month;

//     return `${formattedDay}/${formattedMonth}/${year}`;
//   }


//   const handleButtonClick = async (e) => {


//     console.log("button clicked")
//     e.preventDefault()
//     // const formattedFromDate = formatDate(fromDate);

//     setLoading(true)



//     // const formattedToDate = formatDate(toDate);
//     if (!fromDate || !toDate || selectedInsurer === "Select") {
//       alert("Please fill in all mandatory fields");
//       setLoading(false)
//       return;
//     }
//     const formattedFromDate = new Date(fromDate).toLocaleDateString('en-GB'); // Assumes 'toDate' is in the format "YYYY-MM-DD"


//     const formattedToDate = new Date(toDate).toLocaleDateString('en-GB'); // Assumes 'toDate' is in the format "YYYY-MM-DD"

//     const BaseUrl = process.env.REACT_APP_API_URL

//     const EndPoint = "/riInsurerBulkService/v1/SFTPUploadLogs"

//     try {
//       const response = await fetch(`${BaseUrl}${EndPoint}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             txnId: "9e5adbbe-39ae-72e8-a8d7-0289147bf002",
//             timestamp: "2023-11-20T16:33:25.387994200",
//             Source: {
//               appType: "WEB",
//               osVersion: "11",
//               deviceId: "",
//               deviceName: "Windows108",
//               deviceOS: "Windows",
//               appVersion: "1.0",
//               clientIp: "0.0.0.0",
//               origin: "RI",
//             },
//             Customer: {
//               insurerCode: insurerCode,
//               "uploadRefNo": "1",
//               fromDate: formattedFromDate,
//               toDate: formattedToDate,
//               trxnStatus: transactionStatus,
//             },
//           }),
//         }
//       );
//       if (response.ok) {
//         setLoading(false)
//         let jsonResponse = await response.json();
//         for (let i = 0; i < jsonResponse.SftpUploadLogs.length; i++) {
//           jsonResponse.SftpUploadLogs[i] = { "Sl_No": i + 1, ...jsonResponse.SftpUploadLogs[i] };
//         }
//         setResponseData(jsonResponse.SftpUploadLogs);
//         setShowTable(true);
//       } else {
//         // Handle errors
//         setLoading(false)
//         const errorResponse = await response.json();
//         if (errorResponse?.displayMessage || errorResponse?.errorDescription) {
//           alert(`Error: ${errorResponse.displayMessage !== undefined ? errorResponse.displayMessage : errorResponse.errorDescription}`);
//         }

//       }
//     } catch (error) {
//       // Handle network errors or any other exceptions
//       alert(`Error: ${error.message}`);

//     }

//   };




//   function handleReset(e) {
//     e.preventDefault();
//     console.log(insurerCode, insurerName, refNum, fromDate, toDate, transactionStatus);
//     // clearing the values
//     setSelectedInsurer("Select");
//     setInsurerCode("");
//     setInsurerName("");
//     setRefNum("");
//     setFromDate("");
//     setToDate("");
//     setShowTable(false)
//     setLoading(false)
//     setTransactionStatus("")
//   }

//   const handleExportClick = () => {
//     // Export response data as Excel
//     exportAsExcel(responseData);
//     console.log("Export Success");
//   };


//   const exportAsExcel = (data) => {
//     const ws = XLSX.utils.json_to_sheet(data);
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//     XLSX.writeFile(wb, 'POCList.xlsx');
//   };

//   const navigate = useNavigate()

//   const backbutton = () => {
//     navigate('menudisplaypage')
//   }

//   const todayDate = new Date().toISOString().split('T')[0];


//   const BaseUrl = process.env.REACT_APP_API_URL
//   const apiUrl = BaseUrl + "/riInsurerBulkService/v1/ListInsurerWithLogo";


//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(apiUrl, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             txnId: uuid(),
//             timestamp: '2023-07-25T09:56:20.501636500',
//             Source: {
//               appType: 'MOBILE',
//               osVersion: '11',
//               deviceId: '',
//               deviceName: 'Mymobile 52',
//               deviceOS: 'Android',
//               appVersion: '2.0',
//               clientIp: '0.0.0.0',
//               origin: '',
//             },
//           }),
//         });

//         const data = await response.json();

//         if (data && data.MasterData) {
//           setInsurerData(data.MasterData);
//           console.log(insurerData)
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []); // Empty dependency array ensures the effect runs once when the component mounts

//   // const handleInsurerSelect = (code, description) => {
//   //   setSelectedInsurer(`${code} / ${description}`);

//   // };

//   const handleInsurerSelect = (selectedValue) => {
//     // Parse selectedValue to get insurer code and description
//     const [code, description] = selectedValue.split('/');

//     // Do something with code and description
//     setInsurerCode(code)
//     console.log('Selected Description:', description);

//     // Set the selected insurer state if needed
//     setSelectedInsurer(selectedValue);
//   };



//   let options = insurerData.map((insurer) => ({
//     value: `${insurer.Code}/${insurer.Description}`,
//     label: `${insurer.Code}/${insurer.Description}`,
//   }));

//   options = [{ value: "Select", label: "Select" }, ...options]



//   const customStyles = {
//     control: (provided) => ({
//       ...provided,
//       width: '398px', // Set your desired width
//       height: '10px', // Set your desired height
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       color: state.isSelected ? 'white' : 'black', // Set your desired text color
//       backgroundColor: state.isSelected ? 'blue' : 'white', // Set your desired background color when selected
//     }),
//   };



//   return (
//     <div>
//       <Header />
//       <div className="MenuPage">
//         <Navbar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
//       </div>
//       <div className="app-insurer">
//         <div>
//           <h1 className="headingp">POC List</h1>
//         </div>

//         <div className="row col-12">
//           <div className='col-12'>
//             <form method="post">
//               <div class='row'>
//                 <div className="col-3 username">
//                   <div>
//                     <label className="label_cls" >Insurer Code/Name* </label>
//                   </div>
//                   <div class='' style={{ width: '100%' }}>
//                     <Select
//                       className="inputboxvu_insure" style={{ padding: '4px' }}
//                       value={{ value: selectedInsurer, label: selectedInsurer }}
//                       options={options}
//                       onChange={(selectedOption) => handleInsurerSelect(selectedOption.value)}
//                       placeholder="Select an insurer"
//                       styles={customStyles}
//                     />
//                   </div>
//                 </div>
//                 <div className="username col-2">
//                   <label className="label_cls" >From Date* </label>
//                   <div class=''>
//                     <input type="date" name="fromDate" value={fromDate} className="inputboxvu_insure form-control" max={todayDate} onChange={(e) => setFromDate(e.target.value)} required />
//                   </div>
//                 </div>
//                 <div className="username col-2">
//                   <label className="label_cls" >To Date* </label>
//                   <div class=''>
//                     <input type="date" name="toDate" value={toDate} className="inputboxvu_insure form-control" max={todayDate} onChange={(e) => setToDate(e.target.value)} required />
//                   </div>
//                 </div>
//                 <div className="username col-1 status-card">
//                   <label className="label_cls" >Status* </label>
//                   <div>
//                     <select className="status-container" value={transactionStatus} onChange={(e) => setTransactionStatus(e.target.value)}>
//                       <option value="">Select</option>
//                       <option value="SUCCESS">SUCCESS</option>
//                       <option value="PENDING">PENDING</option>
//                       <option value="FAILURE">FAILURE</option>
//                     </select>
//                   </div>
//                 </div>

//                 <div className="username col-4">
//                   <ActionButton handleBack={backbutton} handleReset={handleReset} handleSubmit={handleButtonClick} />
//                 </div>
//                 <p className="note"> Note: (Mandatory fields Insurer Code/Name, From Date,To Date,Status ) </p>
//               </div>
//               <div className="loading">
//                 <RotateSpinner
//                   size={70}
//                   color="#f99117"
//                   loading={loading}
//                 />
//               </div>
//             </form>
//           </div>
//           <div className='col-12'>
//             <div className="item-insurer">
//               <div className="tableContainer-insurer">
//                 {showTable && (
//                   <div className="">
//                     <button onClick={handleExportClick} className="export-button">Export as Excel</button>
//                     <div class=''>
//                       <Paper sx={{ width: '100%', overflow: 'hidden' }}>
//                         <TableContainer sx={{ maxHeight: 440 }}>
//                           <Table stickyHeader aria-label="sticky table">
//                             <TableHead>
//                               <TableRow>
//                                 {columns.map((column) => (
//                                   <TableCell
//                                     key={column.id}
//                                     align={column.align}
//                                     style={{ minWidth: column.minWidth }}
//                                   >
//                                     {column.label}
//                                   </TableCell>
//                                 ))}
//                               </TableRow>
//                             </TableHead>
//                             <TableBody>
//                               {responseData
//                                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                                 .map((row, index) => {
//                                   debugger;
//                                   console.log(row, index);
//                                   return (
//                                     <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
//                                       {columns.map((column) => {
//                                         const value = row[column.id];
//                                         return (
//                                           <TableCell key={column.id} align={column.align}>
//                                             {/* {column.format && typeof value === 'number'
//                                               ? column.format(value)
//                                               : value} */}
//                                             {value}
//                                           </TableCell>
//                                         );
//                                       })}
//                                     </TableRow>
//                                   );
//                                 })}
//                             </TableBody>
//                           </Table>
//                         </TableContainer>
//                         <TablePagination
//                           rowsPerPageOptions={[5, 10, 25, 100]}
//                           component="div"
//                           count={responseData.length}
//                           rowsPerPage={rowsPerPage}
//                           page={page}
//                           onPageChange={handleChangePage}
//                           onRowsPerPageChange={handleChangeRowsPerPage}
//                         />
//                       </Paper>
//                     </div>

//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>

//       </div>
//     </div>

//   );
// }

function POCList(props) {
  return <Poc isPOC={true} />;
}
export default POCList;



