
import React from "react";

export default function ActionButton({ handleSubmit, handleReset, handleBack }) {

    return (
        <>
            <button onClick={handleSubmit} className="btn_insurer">SUBMIT</button> &nbsp;
            <button onClick={handleReset} className="btn_insurer">RESET</button> &nbsp;
            <button onClick={handleBack} className="btn_insurer">BACK</button> &nbsp;
        </>
    );
}