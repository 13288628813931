import { Navigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";

export default function ProtectedRoute({ component: C}) {

  const [isAuthenticated, userHasAuthenticated] = useState(true);

  const currentSession = () =>{
    const userId  = sessionStorage.getItem("userId");
    // console.log(userId , "userid",isAuthenticated)
    return userId ===null ? userHasAuthenticated(false) : userHasAuthenticated(true);
  }

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      // console.log("reload")
      await currentSession();
    } catch (e) {
      alert(e);
    }
  }


  if (!isAuthenticated) {
   return <Navigate to="/register" replace />;
    }
  return <C/>;
  };