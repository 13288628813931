import React from "react";
import ReactDOM from "react-dom/client";
// import 'primeicons/primeicons.css';
import { PrimeReactProvider } from "primereact/api";
// import 'primeflex/primeflex.css';
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import "./index.css";
import "./flags.css";
import App from "./App";
import { Toast } from "primereact/toast";
import { ToastContextProvider } from "./toast-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContextProvider>
      <App />
    </ToastContextProvider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
