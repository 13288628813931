



  export const NomineeDetails=[
    "nomineeName",
"relationship",
"age",
"appointeeName",
"appointeeRelationship"

  ];
  export const InsuredMembers=[
    "occupation",
    "relationship",
    "gender",
    "dateOfBirth",
    "familyDiscount",
    "assignee",
    "cumulativeBonus",
    "loading",
    "smokerTobacoChewer",
    "selfFamily",
    "voluntaryDiscount",
    "waiverPayments",
    "addressDetails",
    "preExistDiseaseDetails",
    "preExistDiseaseSince"
    
  ];
  export const array = [
    "insuranceCmpnyNm",
    "eiano",
    "transactionType",
    "policyIssuingOffice",
    "appSerialNum",
    "coverNoteNo",
    "endorsementNo",
    "policyNo",
    "policyStartDate",
    "policyEndDate",
    "startTime",
    "endTime",
    "policyIssuedDate",
    "endorsementEffectiveDate",
    "endorsementStarttime",
    "endorsementEndtime",
    "insuredName",
    "productType",
    "productTypeOthers",
    "termsConditions",
    "sumInsured",
    "floaterInd",
    "floaterAmt",
    "coverageType",
    "policyStatus",
    "productDescription",
    "productcode",
    "addonDescription",
    "addonCoverageamt",
    "certificate80d",
    "coPaymenPer",
    "perVoluntaryDeductible",
    "networkHospitalsList",
    "copayStatus",
    "coPaymenPer",
    "waitingPeriodStatus",
    "criticalIllnessCovered",
    "preExistDiseaseSince",
    "address1",
    "address2",
    "address3",
    "city",
    "district",
    "state",
    "country",
    "pincode",
    "investHorizon",
    "addressVerified",
    "addressCreatedDt",
    "addressUpdatedDt",
    "addressEiaserviceFeedgenenabled",
    "addressEiaserviceFeedgendate",
    "addressWorkflowMsgIdImage",
    "addressManualWorkflowMsgId",
    "addressExpiryDate",
    
  ];

  export const bankDetails = [
    "payeeAcHolderFirstName",
    "payeeAcNo",
    "payeeBankName",
    "payeeBranchName",
    "payeeIfscCode",
  ];

  export const communicationAddressDetails = [
    "commAddress1",
    "commAddress2",
    "commAddress3",
    "commCity",
    "commDistrict",
    "commCountry",
    "commState",
    "commPincode",
  ];

  export const permanentAddressDetails = [
    "address1",
    "address2",
    "address3",
    "city",
    "district",
    "country",
    "state",
    "pincode",
    "addressEiaserviceFeedgendate",
  ];

  export  const nomineeDetails = [
    "nomineeName",
    "relationship",
    "age",
    "appointeeName",
    "appointeeRelationship",
  ];
  export  const nomineeDetailsMotor = [
    "nomineeName",
    "appointeeName",
    "appointeeRelationship",
    "namedPersonName",
    "namedPersonAge",
    "namedPersonRelationship",
    "namedPersonGender",
    "namedPersonDob",
    "passengerName",
    "passengerRelationship",
    "nomineeAge",
    "nomineeRelationship"
  ];

  export const VehicleDetails = [
    "bodyStyleDescription",
    "geographicalExtention",
    "placeRegistration",
    "totalIdv",
    "vehicleBodyType",
    "vehicleCarryinbCapacity",
    "vehicleChasisNo",
    "vehicleClassCode",
    "vehicleColor",
    "vehicleCubicCapacity",
    "vehicleEngineNo",
    "vehicleFuelType",
    "vehicleManufacturer",
    "vehicleModel",
    "vehicleRegistrationNo",
    "vehicleYear"
  ];

  export const AgentDetails = [
    "intermediaryCode",
    "intermediaryContactNo",
    "intermediaryLicenceNo",
    "intermediaryName",
    "intermediaryType"
  ]

  export  const DeductibleDetails = [
    "compulsaryDeduct",
    "geoCoverage",
    "voluntaryDeduct"
  ]

  export const claimDetails = [
    "intimationNo",
    "intimationDate",
    "admittedAmount",
    "approvalDate",
    "datePreauth",
    "amountPreauth",
    "grossAmount",
    "lossType",
    "netAmtPaid",
    "paymentDate",
    "paymentRefNo",
    "paymentType",
    "",
  ];

  export const premiumCalculationDetails = [
    "totalPremium",
    "grossPremium",
    "renewalGst",
    "renewalStampDuty",
  ];

  export const insurerDetails = [
    "addressDetails",
    "assignee",
    "preExistDiseaseSince",
    "preExistDiseaseDetails",
    "occupation",
    "gender",
    "relationship",
    "dateOfBirth",
    "familyDiscount",
    "cumulativeBonus",
    "loading",
    "smokerTobacoChewer",
    "selfFamily",
    "voluntaryDiscount",
    "waiverPayments",
  ];
  export const HealthDetails = [
    "insuranceCmpnyNm",
    "eiano",
    "transactionType",
    "policyIssuingOffice",
    "appSerialNum",
    "coverNoteNo",
    "endorsementNo",
    "policyNo",
    "policyStartDate",
    "policyEndDate",
    "startTime",
    "endTime",
    "policyIssuedDate",
    "endorsementEffectiveDate",
    "endorsementStarttime",
    "endorsementEndtime",
    "insuredName",
    "productType",
    "productTypeOthers",
    "termsConditions",
    "sumInsured",
    "floaterInd",
    "floaterAmt",
    "coverageType",
    "policyStatus",
    "productDescription",
    "productcode",
    "addonDescription",
    "addonCoverageamt",
    "certificate80d",
    "coPaymenPer",
    "perVoluntaryDeductible",
    "networkHospitalsList",
    "copayStatus",
    "coPaymenPer",
    "waitingPeriodStatus",
    "criticalIllnessCovered",
    "preExistDiseaseSince",
    "address1",
  ];
  export const Addressvalue = [
    "address1",
    "address2",
    "address3",
    "city",
    "district",
    "state",
    "country",
    "pincode",
    "investHorizon",
    "addressVerified",
    "addressCreatedDt",
    "addressUpdatedDt",
    "addressEiaserviceFeedgenenabled",
    "addressEiaserviceFeedgendate",
    "addressWorkflowMsgIdImage",
    "addressManualWorkflowMsgId",
    "addressExpiryDate",
  ];
  export const ContactDetails = [
    "mobileNo",
    "mobileCountryCode",
    "mobileNoVerified",
    "mobileNoCreatedDt",
    "mobileNoUpdatedDt",
    "mobileNoEiaserviceFeedgenenabled",
    "mobileNoEiaserviceFeedgendate",
    "mobileNoWorkflowMsgId",
    "mobileNoExpiryDate",
    "email",
    "emailVerified",
    "emailCreatedDt",
    "emailUpdatedDt",
    "emailEiaserviceFeedgenenabled",
    "emailEiaserviceFeedgendate",
    "emailWorkflowMsgId",
    "emailExpiryDate"
    ];
    export const MotorclaimDetails= [
        "intimationNo",
        "intimationDate",
        "admittedAmount",
        "settlementDate",
        "grossAmount",
        "lossType",
        "netAmtPaid",
        "paymentDate",
        "paymentRefNo",
        "paymentType",
        "primaryNatureLoss",
        "approvedStmentAmt",
        "claimStatus",
        "claimNo",
        "claimApprovalDate",
        "datePreauth",
        "amountPreauth",
        "notificationDate",
        "cashlessGeragesList"
    ]
    


 
