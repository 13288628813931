import React, { useState, useEffect, useRef } from "react";
import { Pagination} from "react-bootstrap";
import manualQueue ,{ inProgress }from "../API/getQueue.jsx";
import BoHeader from "../Bocomponents/Header";
import { postApporve } from "../API/postData";
import "react-datepicker/dist/react-datepicker.css";
import exportManualExcel from "../Utilities/excel";
import "./new.css";
import BoSidebar from "../Bocomponents/sideNav";
import manualPayload from "../Utilities/payload";
import FiltersComponent from "../Bocomponents/filters";
import TransactionModal from "../Bocomponents/modal";
import TransactionTable from "../Bocomponents/Table";
import Loading from "../Bocomponents/Loader.jsx";
import CustomToast from "../Bocomponents/Alert.jsx";
import {formatDate, oneYearAgo, oneMonthAgo} from "../Utilities/dateformater.jsx"


const NewTransaction = () => {
  
  const Status="New"
  const currentDate = new Date(); 
  const oneYearAgoDate = oneYearAgo(currentDate);
  const oneMonthAgoDate=oneMonthAgo(currentDate);
  // console.log(oneMonthAgoDate,"----------------oneMonthAgoDate");
 
  const fromDateInitial = formatDate(oneMonthAgoDate);
  const toDateInitial=formatDate(currentDate)
// console.log(fromDateInitial,toDateInitial,"======todate")
 
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(fromDateInitial);
  const [toDate, setToDate] = useState(toDateInitial);
  const [fromUpdate, setFromUpdate] = useState("");
  const [toUpdate, setToUpdate] = useState("");
  // const [msgId,setMsgId]=useState("")
  
  let Valuemsg = data[0]?.msgId ?data[0]?.msgId:""
 
 

  

  


  useEffect(() => {

    manualQueue(
      Status,
      fromDate,
      toDate,
      fromUpdate,
      toUpdate,
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
    
  }, []);
  

  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dateFilter, setDateFilter] = useState("");
  const [serviceTypeFilter, setServiceTypeFilter] = useState("");
  const [manualStatus, setManualStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [createdFrom, setCreatedFrom] = useState(null);
  const [createdTo, setCreatedTo] = useState(null);
  const [insurerFilter, setInsurerFilter] = useState("");
  const [addressCount, setAddressCount] = useState(0);
  const [neftCount, setNeftCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [line1,setLine1]=useState("");
  const [line2,setLine2]=useState("");
  const [line3,setLine3]=useState("");
  const [addcity,setAddCity]=useState("");
  const [pincode, setPincode] = useState("");
  const [msgValue,setMsgValue]=useState("");
  const [state,setState]=useState("");``
  const [accNo,setAccNo]=useState("");
  const [Ifsc,setIfsc]=useState("");
  const [bankName,setBankName]=useState("");
  const [branchName,setBranchName]=useState("");
  const [kycProofId,setKycProofId]=useState("")
  const [poaCode,setPoaCode]=useState("")
  const [pobCode, setPobCode] = useState("")







  //filters
  const handleCreatedFromChange = (date) => {
    // console.log("calling here");
    setCreatedFrom(date);
    const formattedDate = formatDate(date);
    const toDateToUse = createdTo ? formatDate(createdTo) : toDateInitial;
    if (!createdTo) {
      setToDate(toDateInitial);
    }
    manualQueue(
      Status,
      formattedDate,
      toDateToUse,
     "",
     "",
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
    
  };
  const handleCreatedToChange = (date) => {
    // console.log(fromDate,"fromdate======>");
    setCreatedTo(date);
    const formattedDate = formatDate(date);
    // console.log(formattedDate,"=====created to");
    const fromDateToUse = createdFrom ? formatDate(createdFrom) : fromDateInitial;
    if (!createdFrom) {
      setToDate(formattedDate);
    }

   
      manualQueue(
        Status,
        fromDateToUse,
        formattedDate,
        "",
        "",
        setData,
        setAddressCount,
        setNeftCount,
        setIsLoading,
        setSuccessMessage,
        setErrorMessage,
        handleCloseAfterDelay
      );
    
  };
  const serviceTypeOptions = ["ADDRESS", "NEFT"];
  const insurerOption = [
    "Reimagine",
    "Kenscio",
    "Yellow messenger",
    "CAMSRep",
    "MCAMSRep",
  ];

  const handleRefresh =  () => {
    let fromdate
    let todate 
    const refreshedFromDate =  createdFrom ? formatDate(createdFrom) : "";
    if(refreshedFromDate==""){
      fromdate=fromDateInitial
      // console.log(fromdate,"<====fromdate in refresh1");
    }else{
      fromdate=refreshedFromDate
      // console.log(fromdate,"<====fromdate in refresh");
    }
    const refreshedToDate = createdTo ? formatDate(createdTo) : "";
    if (refreshedToDate==""){
      todate=toDateInitial
    }
    else{
    todate=refreshedToDate
    }

    manualQueue(
      Status,
      fromdate,
      todate,
      "",
      "",
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
  };
  
  //ecport to excel
  const handleExport = () => {
    const date = new Date();
    const formattedDate = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    const fileName = `Manual-Transactions_${formattedDate}.xlsx`;

    const manualData = data.filter((item) => {
      const createdDate = new Date(item.createdDate);
      if (
        (createdFrom && createdDate < createdFrom) ||
        (createdTo && createdDate > createdTo)
      ) {
        return false;
      }

      if (serviceTypeFilter && item.serviceType !== serviceTypeFilter) {
        return false;
      }
      if (insurerFilter && item.insurerName !== insurerFilter) {
        return false;
      }

      return true;
    });

    exportManualExcel(manualData, fileName);
  };
// Apply filters to data
const filteredServiceTypeData = serviceTypeFilter
  ? data.filter((item) => item.serviceType.toUpperCase() === serviceTypeFilter)
  : data;
const filteredInsurerData = insurerFilter
  ? filteredServiceTypeData.filter((item) => item.insurerName === insurerFilter)
  : filteredServiceTypeData;
  const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = filteredInsurerData.slice(indexOfFirstItem, indexOfLastItem);

// Pagination
const totalPages = Math.ceil(filteredInsurerData.length / itemsPerPage);
  const maxPagesToShow = 5;
  let startPage, endPage;
  
  if (totalPages <= maxPagesToShow) {
    // Less than or equal to 5 total pages, so show all
    startPage = 1;
    endPage = totalPages;
  } else {
    // More than 5 total pages, so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // Current page near the start
      startPage = 1;
      endPage = maxPagesToShow;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // Current page near the end
      startPage = totalPages - maxPagesToShow + 1;
      endPage = totalPages;
    } else {
      // Current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }
  
  // Helper function to generate page numbers
  const range = (start, end) => Array.from({ length: end - start + 1 }, (_, i) => start + i);
  
  const pageNumbers = range(startPage, endPage);

  // Modal handling
  const handleClose = () => {setShowModal(false), clearState()}
  const [enableRowClick, setEnableRowClick] = useState(true);
  const handleRowClick = (rowData) => {
    if (enableRowClick) {
      setSelectedRow(rowData);
      setShowModal(true);
    } 
  };

  // Functions to handle filters

  const handleServiceTypeFilter = (selectedServiceType) => {
    const formattedServiceType = selectedServiceType.toUpperCase();
    // console.log('Formatted service type:', formattedServiceType);
    setServiceTypeFilter(formattedServiceType);
  };
  const handleInsurerFilter = (selectedInsurer) => {
    setInsurerFilter(selectedInsurer);
  };
  const handleReset = () => {
    setCreatedTo(null);
    setCreatedFrom(null);
   
    setServiceTypeFilter("");
    setInsurerFilter("");
    handleRefresh();
   
    setFromDate("");
    setToDate("");

   
    setFromDate("")
    setToDate("")
    manualQueue(
      Status,
      fromDateInitial,
      toDateInitial,
      "",
      "",
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );

  };

 
  const formattedDate = `${currentDate.toISOString()}`;

  const handleSubmit = async () => {
    // console.log(rejectReason,rejectComments,"---------reject Reason and comments while submitting");
    
    const isRowSelected = !!selectedRow;
    const isManualStatusSet = manualStatus !== "";

    if (isRowSelected && isManualStatusSet !== "") {
      if(!isManualStatusSet){
        setErrorMessage("Please Select Any option before Submitting")
        return;
      }
      if (selectedRow) {
        let action;
       
        if (manualStatus == "Approved") {
          action = "APPROVE";
        } else {
          action = "REJECT";
          
          if ((rejectReason !== "Others" && rejectReason === "") || (rejectReason === "other" && rejectComments === "")) {
            setErrorMessage("Please provide a valid reject reason and comments.");
            return;
        }
        }
        setIsLoading(true);
        
        try {
          const boData = manualPayload(
            selectedRow,
            action,
            formattedDate,
            rejectReason,
            rejectComments,
            line1,
            line2,line3,addcity,pincode,state,accNo,
            Ifsc,
            bankName,
            msgValue,
            branchName,
            kycProofId,poaCode,pobCode
          );
          // console.log(boData, "data review========<>");

          const response = await postApporve(boData);

          // console.log("API Response:", response);
          if (response) {
            handleClose();
            setSuccessMessage("Transaction Updated successfully.");
            handleCloseAfterDelay(setSuccessMessage);
            handleRefresh()
          }
        } catch (error) {
          console.error("API Error:", error);
          setErrorMessage("Error Updating transaction. Please try again.");

          handleCloseAfterDelay(setErrorMessage);
        } finally {
          setIsLoading(false);
        } 
      }
    
    } else {
      console.warn("Please select an action before submitting.");
    }
  };
  const handleCloseAfterDelay = (setMessageFunction) => {
    setTimeout(() => {
      setMessageFunction("");
    }, 3000);
  };
  
  const handleMaualstatusChange = (status,add1,add2,add3,addCity,pincode,state,accNo,Ifsc,bankName,branchName,msgValue,kycProofId,poaCode,pobCode) => {

    setManualStatus(status);
    setLine1(add1);
    setLine2(add2);
    setLine3(add3);
    setAddCity(addCity);
    setPincode(pincode);
    setState(state);
    setAccNo(accNo);
    setIfsc(Ifsc);
    setBankName(bankName);
    setMsgValue(msgValue)
    setBranchName(branchName);
    setKycProofId(kycProofId);
    setPoaCode(poaCode);
    setPobCode(pobCode)
    // console.log("Manual Status in NewTransaction:", manualStatus);
  };
 
  ////visible columns///////
  const visibleColumns = [
    "Transaction Number",
    "Customer ID",
    "Customer Name",
    "Insurer",
    "Service",
    "Created Date",
    "Manual Status",
  ];
  const [rejectReason, setRejectReason] = useState("");
  const [rejectComments, setRejectComments] = useState("");
  const handleRejectReasonChange = (reason) => {
    setRejectReason(reason);
  };

  const handleRejectCommentsChange = (comments) => {
    setRejectComments(comments);
  };
  const clearState = () => {
    setRejectReason("");
    setRejectComments("");
    setManualStatus("");
    setLine1("");
    setLine2("");
    setLine3("");
    setAddCity("");
    setPincode("");
    setMsgValue("")
    setState("");
    setKycProofId("");
    setPoaCode("");
    setPobCode("");
    setAccNo("");
    setIfsc("");
    setBankName("");
    setBranchName("");
   
  };
  return (
    <div>
      {isLoading && <Loading />}
      {successMessage && (
        <CustomToast
          variant="success"
          message={successMessage}
          onClose={() => setSuccessMessage("")}
        />
      )}

      {errorMessage && (
        <CustomToast
          variant="danger"
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}

      <BoHeader />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <BoSidebar />

        <div className="tableContainer">
        <span style={{color:'#1b67cc', fontSize:'20px'}}>Policy Genie Back Office - Manual Queue - New Status </span>
          <FiltersComponent
            createdFrom={createdFrom}
            createdTo={createdTo}
            handleCreatedFromChange={handleCreatedFromChange}
            handleCreatedToChange={handleCreatedToChange}
            serviceTypeOptions={serviceTypeOptions}
            serviceTypeFilter={serviceTypeFilter}
            handleServiceTypeFilter={handleServiceTypeFilter}
            insurerOption={insurerOption}
            insurerFilter={insurerFilter}
            handleInsurerFilter={handleInsurerFilter}
            handleReset={handleReset}
            handleRefresh={handleRefresh}
            handleExport={handleExport}
            currentPage="NewTransaction"
          />
          <TransactionTable
            data={data}
            currentItems={currentItems}
            handleRowClick={handleRowClick}
            visibleColumns={visibleColumns}
          />

          <div>
            <p className="bottom-para">
              Total Entries- Address: {addressCount} | Neft: {neftCount}
            </p>
          </div>

<Pagination className="paginationContainer">
  <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
  <Pagination.Prev
    onClick={() => setCurrentPage(currentPage - 1)}
    disabled={currentPage === 1}
  />
  {pageNumbers.map((page) => (
    <Pagination.Item
      key={page}
      active={page === currentPage}
      onClick={() => setCurrentPage(page)}
    >
      {page}
    </Pagination.Item>
  ))}
  <Pagination.Next
    onClick={() => setCurrentPage(currentPage + 1)}
    disabled={currentPage === totalPages}
  />
  <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
</Pagination>

          <TransactionModal
            className="custom-modal"
            showModal={showModal}
            handleClose={handleClose}
            isLoading={isLoading}
            selectedRow={selectedRow}
            handleMaualstatusChange={handleMaualstatusChange}
            handleSubmit={handleSubmit}
            currentpage="new"
            handleRejectReasonChange={handleRejectReasonChange}
            handleRejectCommentsChange={handleRejectCommentsChange}
          />
        </div>
      </div>
    </div>
  );
};

export default NewTransaction;
