import React, { useMemo, } from "react";
import { useTable, } from "react-table";


const POCTableData = ({ data }) => {
  const columns = useMemo(
    () => [
     
      { Header: "POLICY_NO1", accessor: "uploadRefNo" },
      { Header: "EIA_NO1", accessor: "userRefId" },
      { Header: "UPLOAD_REF_NO1", accessor: "requestType" },
      { Header: "USER_REF_ID1", accessor: "insurerCode" },
      { Header: "FILE_TYPE", accessor: "fileId" },
      { Header: "INSURANCE_CMPNY_CD", accessor: "fileName" },
      { Header: "FILE_ID", accessor: "dateOfEntry" },
      { Header: "FILE_NAME", accessor: "numOfRec" },
      { Header: "DATE_OF_GENERATION", accessor: "typeOfFile" },
      { Header: "DATE_OF_ENTRY", accessor: "folderPath" },
      { Header: "PROCESSED_DATE", accessor: "individualBulk" },
      { Header: "UPLOAD_STATUS", accessor: "UPLOAD_STATUS" },
      { Header: "REJECTION_REASON", accessor: "reqResErr" },
      { Header: "TRXN_TYPE", accessor: "remarks" },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data });

  return (
    <div className="table-container">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="item-header" {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default POCTableData;














// // function POCForm(props) { 
// //   const [insurer, setInsurer] = useState(''); 
// //   const [name, setName] = useState(''); 
// //   const [from, setFrom] = useState(''); 
// //   const [to, setTo] = useState(''); 
  
// //   const changeInsurer = (event) => { 
// //     setInsurer(event.target.value); 
// //   }; 
  
// //   const changeName = (event) => { 
// //     setName(event.target.value); 
// //   }; 
// //   const changeFrom = (event) => { 
// //     setFrom(event.target.value); 
// //   }; 
// //   const changeTo = (event) => { 
// //     setTo(event.target.value); 
// //   }; 
  
// //   const transferValue = (event) => { 
// //     event.preventDefault(); 
// //     const val = { 
// //       insurer, 
// //       name, 
// //       from,
// //       to
// //     }; 
// //     props.func(val); 
// //     clearState(); 
// //   }; 
  
// //   const clearState = () => { 
// //     setInsurer(''); 
// //     setName(''); 
// //     setFrom(''); 
// //     setTo(''); 

// //   }; 
  
// //   return ( 
// //     <div> 
// //       <label>Insurer</label> 
// //       <input type="text" value={insurer} onChange={changeInsurer} /> 
// //       <label>Name</label> 
// //       <input type="text" value={name} onChange={changeName} /> 
// //       <label>From</label> 
// //       <input type="text" value={from} onChange={changeFrom} /> 
// //       <label>To</label> 
// //       <input type="text" value={to} onChange={changeTo} /> 
// //       <button onClick={transferValue}> Click Me</button> 
// //     </div> 
// //   ); 
// // } 
  
// // export default POCForm;


