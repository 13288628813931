import axios from 'axios';
import dayjs from 'dayjs';

import cryptoEncryptionDecryption from '../crypto';

function getObjects() {
  return axios.get("http://yourservice/yourendpoint");
}

async function postData(url, payload) {
  const currentTimeStamp = dayjs(new Date()).valueOf();
  const access_token = sessionStorage.getItem("access_token");
  const token_expires_at = sessionStorage.getItem("token_expires_at");

  if (
    !access_token
    //|| currentTimeStamp >= Number(token_expires_at)
  ) {
    return await getAccessToken(url, payload);
  } else {
    return await APICall(url, payload);
  }
}

async function getAccessToken(url, payload) {
  const currentTimeStamp = dayjs(new Date()).valueOf();
  const baseUrl = process.env.REACT_APP_ACCESS_TOKEN_URL;
  return await axios
    .post(
      baseUrl,
      {
        grant_type: "client_credentials",
      },
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_OAUTH_BEARER_TOKEN}`,
          "Content-Type": "application/json",
          "x-oauth-usage": "STERLING",
        },
      }
    )
    .then(async (res) => {
      sessionStorage.setItem("access_token", res?.data?.access_token);
      sessionStorage.setItem(
        "token_expires_at",
        String(
          res?.data?.access_token * res?.data.expires_in + currentTimeStamp
        )
      );
      return await APICall(url, payload);
    })
    .catch((error) => {
      // console.log(error);
    });
}

async function APICall(url, payload) {
  const access_token = sessionStorage.getItem("access_token");
  if (process.env.REACT_APP_MODE !== "PROD") {
    console.log(`Payload---> ${url} ---->`, payload, access_token);
  }
  const baseUrl = process.env.REACT_APP_API_URL + url;
  const pay = cryptoEncryptionDecryption.Encrypt(payload);
  return await axios
    .post(baseUrl, pay, {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(async (response) => {
      console.log(response);
      const res = JSON.parse(
        cryptoEncryptionDecryption.Decrypt(await response?.data)
      );
      if (process.env.REACT_APP_MODE !== "PROD") {
        console.log(`Decrypted---> ${url} ---->`, res);
      }
      return res;
    })
    .catch((error) => {
      const displayMessage = JSON.parse(
        cryptoEncryptionDecryption.Decrypt(error.response.data)
      )?.displayMessage;
      alert(displayMessage);
      return cryptoEncryptionDecryption.Decrypt(error.response.data);
    });
}

export { getObjects, postData };
