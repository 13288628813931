import React, { useState, setState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import MenuDisplayPage from "../MenuDisplayPage";
import InsurerDetails from "../InsurerDetails/Insurer";
import { useNavigate } from "react-router-dom";
import { redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import logo from "../Login/logo.svg";

import Header from "../Header/Header";
import { RotateSpinner } from "react-spinners-kit";
import showPwdImg from "../Registration/show-password.svg";
import hidePwdImg from "../Registration/hide-password.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AlertDialog from "../Common/DailogBox";
import { useToastContext } from "../toast-context";
import axios from "axios";

const ResetPasswordNew = () => {
  const navigate = useNavigate();

  const { showToast } = useToastContext();

  // const showSuccess = () => {
  //   toast.current.show({
  //     severity: "success",
  //     summary: "Success",
  //     detail: "Message Content",
  //     life: 3000,
  //   });
  // };

  const userId = sessionStorage.getItem("userId");

  const currentDate = new Date();

  // Format the date components individually
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = currentDate.getDate().toString().padStart(2, "0");
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const milliseconds = currentDate
    .getMilliseconds()
    .toString()
    .padStart(3, "0");

  // Combine the components into the desired format
  const timeStamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}00`;

  // const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [passwordCurrent, setPasswordCurrent] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [dateTimeStamp, setDateTimeStamp] = useState(timeStamp);

  const [error, setError] = useState("");
  const [success, setSucsess] = useState("");

  const [lastName, setLastName] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isRevealPwdCurrent, setIsRevealPwdCurrent] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwdconfirm, setIsRevealPwdConfirm] = useState(false);
  const [resetDailog, setResetDailog] = useState(false);

  const handleLogin = async (e) => {
    // console.log("Button clicked!"); // Add this line

    e.preventDefault(); // Add this line
    // showToast({
    //   severity: "error",
    //   summary: "Success Message",
    //   detail: "Order submitted",
    // });
    // Check for mandatory fields
    //showSuccess();

    const BaseUrl = process.env.REACT_APP_API_URL;

    const EndPoint = "/riInsurerBulkService/v1/AdminLogin ";

    const passRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Za-z])[a-zA-Z0-9!@#$%^&*]{4,16}$/g;
    const isValid = passRegex.test(passwordNew);

    if (passwordCurrent == "" || passwordNew == "" || passwordConfirm == "") {
      showToast({
        severity: "warn",
        summary: "Fields must not be empty",
        // detail: "Order submitted",
      });
      // alert("Fields must not be empty");
    } else if (!isValid || passwordNew.length < 8) {
      // alert("Please enter valid New password");
      showToast({
        severity: "warn",
        summary: "Please enter valid New password",
        // detail: "Order submitted",
      });
    } else if (passwordNew !== passwordConfirm) {
      showToast({
        severity: "warn",
        summary: "Password does not match. Try again!",
        // detail: "Order submitted",
      });
      // alert("Password does not match. Try again!");
    } else {
      try {
        const response = await axios.post(`${BaseUrl}${EndPoint}`, {
          timestamp: "2024-01-25T19:19:45.238",
          txnId: "9e5adbbe-39ae-72e8-a8d7-0289147bf002",
          Source: {
            appType: "REACT",
            osVersion: "11",
            deviceId: "",
            deviceName: "DESKTOP",
            deviceOS: "WINDOWS",
            appVersion: "2.0",
            clientIp: "0.0.0.0",
            origin: "RI",
          },
          Customer: {
            userId: userId,
            oldPassword: passwordCurrent,
            newPassword: passwordNew,
            conformPassword: passwordConfirm,
          },
        });

        // console.log(response.data.errorDescription);
        if (response) {
          // console.log(response);
          showToast({
            severity: "warn",
            summary: `${response.data.displayMessage}`,
            // detail: "Order submitted",
          });
          // alert(response.data.displayMessage);
          navigate("/newlog");
        }
      } catch (error) {
        // console.log(error);
        showToast({
          severity: "warn",
          summary: `${error.response.data.errorDescription}`,
          // detail: "Order submitted",
        });
        // alert(error.response.data.errorDescription);
      }
    }

    //Prod
    setLoading(true);
  };

  const inputAlphaNumericWithSpl = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z-_/\\]+$/gm, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const backbutton = () => {
    navigate("/newlogin");
  };

  const onReset = () => {
    setPasswordCurrent("");
    setPasswordNew("");
    setPasswordConfirm("");
  };

  return (
    <div className="newlog-card">
      <div className="newlogin-containernew ">
        {/* <div class="heading">Sign In</div> */}
        <form action="" className="form">
          <div className="heading-signinnew">RESET PASSWORD</div>
          <div className="input for-icon d-flex">
            <input
              required=""
              class="input-pass new-pass"
              type={isRevealPwdCurrent ? "text" : "password"}
              name="password"
              placeholder="Current Password"
              value={passwordCurrent}
              onChange={(e) => setPasswordCurrent(e.target.value)}
            />
            {isRevealPwdCurrent ? (
              <RemoveRedEyeIcon
                className="reset-icon-log"
                title={isRevealPwdCurrent ? "Hide password" : "Show password"}
                // src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwdCurrent((prevState) => !prevState)}
              />
            ) : (
              <VisibilityOffIcon
                className="reset-icon-log"
                title={isRevealPwdCurrent ? "Hide password" : "Show password"}
                // src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwdCurrent((prevState) => !prevState)}
              />
            )}
          </div>
          <div className="input for-icon d-flex">
            <input
              required=""
              className="input-pass new-pass"
              type={isRevealPwd ? "text" : "password"}
              name="password"
              placeholder="New Password"
              value={passwordNew}
              onChange={(e) => setPasswordNew(e.target.value)}
            />
            {isRevealPwd ? (
              <RemoveRedEyeIcon
                className="reset-icon-log"
                title={isRevealPwd ? "Hide password" : "Show password"}
                // src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            ) : (
              <VisibilityOffIcon
                className="reset-icon-log"
                title={isRevealPwd ? "Hide password" : "Show password"}
                // src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            )}
          </div>
          <div className="input for-icon d-flex">
            <input
              required=""
              class="input-pass new-pass"
              type={isRevealPwdconfirm ? "text" : "password"}
              name="password"
              placeholder="Confirm Password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            {isRevealPwdconfirm ? (
              <RemoveRedEyeIcon
                className="reset-icon-log"
                title={isRevealPwdconfirm ? "Hide password" : "Show password"}
                // src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwdConfirm((prevState) => !prevState)}
              />
            ) : (
              <VisibilityOffIcon
                className="reset-icon-log"
                title={isRevealPwdconfirm ? "Hide password" : "Show password"}
                // src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwdConfirm((prevState) => !prevState)}
              />
            )}
          </div>
          {/* <span class="forgot-password"><a href="#">Forgot Password ?</a></span> */}
          <div className="btn-card">
            <input
              className="login-buttonnew"
              type="submit"
              value="Submit"
              onClick={handleLogin}
            />
            <input
              className="login-buttonnew"
              type="submit"
              value="Reset"
              onClick={onReset}
            />
            <input
              className="login-buttonnew"
              type="submit"
              value="Back"
              onClick={backbutton}
            />
          </div>
        </form>

        {/* <div class="social-account-container">
          <span class="title">Or Sign in with</span>
          <div class="social-accounts">
            <button class="social-button google">
              <svg class="svg" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 488 512">
                <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path>
              </svg></button>
            <button class="social-button apple">
              <svg class="svg" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path>
              </svg>
            </button>
            <button class="social-button twitter">
              <svg class="svg" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
              </svg>
            </button>
          </div>
        </div> */}
        {/* <span class="agreement"><a href="#">Learn user licence agreement</a></span> */}
      </div>
      <div className="desc-card">
        <p className="reset-desc">Note : To change your password:</p>

        <ul className="reset-desc">
          <li>Password must be at least 8-15 characters.</li>
          <li>Password must contain one number and one special character.</li>
        </ul>
      </div>
    </div>
  );
};

export default ResetPasswordNew;
