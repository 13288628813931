import './style.css';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  Link,
  useNavigate,
} from 'react-router-dom';
//
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from 'react-simple-captcha';
import { RotateSpinner } from 'react-spinners-kit';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import AlertDialog from '../Common/DailogBox';
import logo from '../Login/logo.svg';
import { postData } from '../Service/service';

// import { axiosInstance } from "../axiosInstance";

function RegistrationForm() {
  const navigate = useNavigate();

  const currentDate = new Date();

  // Format the date components individually
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = currentDate.getDate().toString().padStart(2, "0");
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const milliseconds = currentDate
    .getMilliseconds()
    .toString()
    .padStart(3, "0");

  // Combine the components into the desired format
  const timeStamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}00`;

  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dateTimeStamp, setDateTimeStamp] = useState(timeStamp);

  const [error, setError] = useState("");
  const [success, setSucsess] = useState("");

  const [lastName, setLastName] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [resetDailog, setResetDailog] = useState(false);

  const handleLogin = async (e) => {
    console.log("Button clicked!"); // Add this line
    e.preventDefault(); // Add this line
    // Check for mandatory fields

    if (!userId || !password) {
      alert("Please fill in all mandatory fields.");
      return;
    }
    //Prod
    setLoading(true);
    const BaseUrl = process.env.REACT_APP_API_URL;

    const EndPoint = "/riInsurerBulkService/v1/AdminLogin";

    // const payload = CryptoEncryption.Encrypt(
    //   JSON.stringify({
    //     userId,
    //     password,
    //   })
    // );
    // console.log(payload, "encrypted paylod-------");

    //CryptoEncryption.Decrypt(reponse)

    try {
      let user_captcha = document.getElementById("user_captcha_input").value;

      if (validateCaptcha(user_captcha) === false) {
        alert("Captcha Does Not Match");
        document.getElementById("user_captcha_input").value = "";
        setLoading(false);
        return 0;
      }

      const response = await postData(
        "/api-client-service/v1/admin/AdminLogin",
        {
          userId,
          password,
        }
      );
      // console.log(response);
      if (response.errorCode === "0") {
        sessionStorage.setItem("userId", userId);
        navigate("/menudisplaypage");
        setSucsess(response.displayMessage);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setError("Network error. Please try again.");
      console.error("Error:", error);
    }
  };

  //captcha code
  useEffect(() => {
    loadCaptchaEnginge(6, "#fff", "#2E2E38");
  }, []);

  // const doSubmit = () => {
  //   let user_captcha = document.getElementById("user_captcha_input").value;

  //   if (validateCaptcha(user_captcha) === true) {
  //     alert("Captcha Matched");
  //     loadCaptchaEnginge(6);
  //     document.getElementById("user_captcha_input").value = "";
  //   } else {
  //     alert("Captcha Does Not Match");
  //     document.getElementById("user_captcha_input").value = "";
  //   }
  // };

  const inputAlphaNumericWithSpl = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z-_/\\]+$/gm, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  return (
    <div>
      <div className="header">
        <div className="logo-bima">
          <Link to="/">
            <img
              alt="img"
              src={logo}
              className="clickable-image"
              style={{ width: "150px", height: "60px" }} // Adjust the width and height as needed
            />
          </Link>
        </div>

        {/* <div className="profile-card">
        <AccountCircleIcon fontSize="large" style={{marginTop:"4px",marginRight:"3px"}} />
        <h1> userID:  <a style={{color:"#f99117"}}>{userId}</a></h1>
      </div> */}
      </div>

      <div className="app-2">
        <div className="form-l">
          {/* <div className='bima-logo'>
                </div> */}
          <form>
            <h1 className="title">LOGIN</h1>
            <div className="form-body">
              <div className="username">
                <label className="form__label">User Id* </label>
                <input
                  type="text"
                  className="text-input add"
                  value={userId}
                  required
                  onChange={(e) => setUserId(e.target.value.toUpperCase())}
                />
              </div>
              {/* <div className="lastname">
                    <label className="form__label" >Last Name </label>
                    <input  type="text" name="" id="lastName" value={lastName}  className="form__input" onChange = {(e) => handleInputChange(e)} />
                </div> */}
              {/* <div className="email">
                    <label className="form__label" >Email* </label>
                    <input type="text" value={email} required onChange={(e) => setEmail(e.target.value)} />
                </div> */}
              <div className="password">
                <label className="form__label">Password* </label>
                <div className="d-flex input-icon-card">
                  <input
                    // type="password"
                    className="text-input add-pass"
                    // className="add-pass"
                    type={isRevealPwd ? "text" : "password"}
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {isRevealPwd ? (
                    <RemoveRedEyeIcon
                      className="img-icon"
                      title={isRevealPwd ? "Hide password" : "Show password"}
                      // src={isRevealPwd ? hidePwdImg : showPwdImg}
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    />
                  ) : (
                    <VisibilityOffIcon
                      className="img-icon"
                      title={isRevealPwd ? "Hide password" : "Show password"}
                      // src={isRevealPwd ? hidePwdImg : showPwdImg}
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    />
                  )}
                </div>
              </div>
              {/* <div className="confirm-password">
                    <label className="form__label" >Confirm Password </label>
                    <input className="form__input" type="password" id="confirmPassword" value={confirmPassword} onChange = {(e) => handleInputChange(e)} />
                </div> */}
            </div>
            <div>
              <div className="container">
                <div className="col">
                  <button className="text-input">
                    <LoadCanvasTemplate reloadText="Reload" />
                  </button>
                </div>

                <div className="col mt-3">
                  <input
                    placeholder="Enter Captcha"
                    id="user_captcha_input"
                    name="user_captcha_input"
                    type="text"
                    className="input-captcha"
                    maxLength={6}
                  />
                </div>
                {/* <div className="col mt-3">
            <div>
              <button
                className="btn btn-primary"
                onClick={doSubmit}
              >
                Submit
              </button>
            </div>
          </div> */}
              </div>
            </div>
            <div className="loading">
              <RotateSpinner size={50} color="#f99117" loading={loading} />
              <AlertDialog flag={resetDailog} />
            </div>
            <div className="footer">
              {" "}
              <button onClick={handleLogin} className="butt">
                SUBMIT
              </button>
              {success ? <div style={{ color: "green" }}>{success}</div> : ""}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegistrationForm;
