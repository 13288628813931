   
const manualPayload = (selectedRow, action, formattedDate,  rejectReason,
  rejectComments,line1,
  line2,line3,addcity,pincode,state,accNo,ifscCode,bankName,Valuemsg,branchName, kycProofId,poaCode,pobCode) => {
    
    return {
      transactionId: selectedRow.transactionId,
      eiaNo: selectedRow.eiaNo,
      txnId:"",
      customerId:selectedRow.transactionId,
      flag: selectedRow.serviceType,
      action: action,
      rejectReason :rejectReason,
      rejectComments:rejectComments,
      addr_line1: line1,
      msgId:Valuemsg,
      addr_line2:line2,
      addr_line3:line3,
      city:addcity,
      pincode:pincode,
      state:state,
      bank_name:bankName,
      ifsc_code:ifscCode,
      neft_account:accNo,
      branch_name:branchName,
      kycProofId:kycProofId,
      poaCode:poaCode,
      pobCode:pobCode,
      reviewedby:sessionStorage.getItem("userId"),
      
    };
  };
  
  export default manualPayload;
  