import React from "react";
import { Table } from "react-bootstrap";


const TransactionTable = ({ currentItems, handleRowClick,visibleColumns,createdFrom,createdTo }) => {
  const columnMapping = {
    "Transaction Number": "transactionId",
    "Customer ID": "eiaNo",
    "CustomerID": "customerId",
    "Customer Name": "customerName",
    "Insurer": "insurerName",
    "Service": "serviceType",
    "Created Date": "createdDate",
    "Manual Status": "manualStatus",
    "Status":"status",
    "Reviewed At": "updatedDate",
    "Rejection Reason": "rejectReason",
    "Rejection Comments": "rejectComments",
    "Field Name": "fieldName",
    "Original Value": "originalValue",
    "New Value": "newValue",
    "Created At":"createdAt",
    "Updated On": "statusUpdatedAt",
    "UpdatedOn":"completedAt",
    "MQ Status": "manualStatus",
    "Screen Last Visited": "",
    "Activity": "",
    "Initiated At": "initiatedAt",
    "Completed At": "completedAt",
    "Mobile Number": "mobile",
    "Email": "email",
    "Verification Status": "status",
    "ProofType": "documentType",
    "Current Details": "currentDetails",
    "Ocr/New Details": "newDetails",
    "Status Updated At": "statusUpdatedAt",
    "Image Link": "imageUrl",
    "Service update status": "serviceUpdateStatus",
    "Service update failure reason": "",
    "Vendor": "",
    "Insurance Company Name": "",
    "Policy Number": "",
    "Due Date": "",
    "Paid Amount": "",
    "Premium Amount": "",
    "Link validation status": "",
    "Link validation error": "",
    "Transaction id": "",
    "eIA details requested at": "",
    "eIA details responded at": "",
    "eIA details enquiry duration": "",
    "eIA details API status": "",
    "eIA details API error": "",
    "eIA details enquiry response": "",
    "eIA details enquiry response error": "",
    "IPRU enquiry requested at": "",
    "IPRU enquiry responded at": "",
    "IPRU enquiry duration": "",
    "IPRU enquiry API status": "",
    "IPRU enquiry API error": "",
    "IPRU enquiry response": "",
    "IPRU enquiry response error": "",
    "Order ID gen requested at": "",
    "Order ID gen responded at": "",
    "Order ID gen duration": "",
    "Order ID gen API status": "",
    "Order ID gen API error": "",
    "Order ID gen response": "",
    "Order ID gen response error": "",
    "Order ID gen Attempt": "",
    "Order_id": "",
    "Pay checkout requested at": "",
    "Pay checkout responded at": "",
    "Pay checkout duration": "",
    "Pay checkout API status": "",
    "Pay checkout API error": "",
    "Pay checkout response": "",
    "Pay checkout response error": "",
    "fetchapayment requested at": "",
    "fetchapayment responded at": "",
    "fetchapayment duration": "",
    "fetchapayment API status": "",
    "fetchapayment API error": "",
    "fetchapayment response": "",
    "fetchpayment captured status": "",
    "email from Razorpay": "",
    "contact from Razorpay": "",
    "fetchpayment error code": "",
    "fetchpayment error description": "",
    "fetchpayment error source": "",
    "fetchpayment error step": "",
    "fetchpayment error reason": "",
    "Dataprocessor requested at": "",
    "Dataprocessor responded at": "",
    "Dataprocessor duration": "",
    "Dataprocessor API status": "",
    "Dataprocessor API error": "",
    "Dataprocessor response": "",
    "Dataprocessor response error": "",
    "CAMS update requested at": "",
    "CAMS update responded at": "",
    "CAMS update duration": "",
    "CAMS update API status": "",
    "ImageLink":"",
    "CAMS update API error": "",
    "CAMS update response": "",
    "CAMS update response error": "",
    " Payment_id": "",
    "Mode of Payment": "",
    "Transaction Initiation Date & Time": "",
    "IsPaymentCheckoutClosedManually": "",
    "Error Reason": "",
    "Error due to": "",
    "Clicked close at": "",
    "PAY NOW CLICK - DATE & Time": "",
    " PAY NOW CLICK - Duration (sec)": "",
    "Razorpay  Clourse - Date & Time": "",
    "Razorpay duration (Sec)": "",
    "Razorpay updation scenario": "",
    "Acknowledgement Closure - Date & Time": "",
    "Acknowledgement page duration (Sec)": "",
    "Feedback - Rating Submit Button (Date & Time)": "",
    "Feedback - Rating duration (sec)": "",
    "Feedback - Comment submission (Date & Time)": "",
    "Feedback - Comment duration (Sec)": "",
    "API requested at":"",
    "API responded at":"",
    "API connection status":"",
    "Disconnect reason":"",
    "Response duration":"",
  };
  return (
    <div className="data-scroll">
    {currentItems.length === 0 ? (
      <p>No data available</p>
    ) : (
    <Table style={{ width: "100%" }} striped bordered hover>
      <thead>
        <tr>
        {visibleColumns.map((column, index) => (
            <th key={index}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
      {currentItems.map((item, index) => (
          <tr key={index} onClick={() => handleRowClick(item)}>
            {visibleColumns.map((column, colIndex) => (
             true? <td key={colIndex}>{item[columnMapping[column]]}</td>:""           
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
    )}
    </div>
  );
};

export default TransactionTable;
