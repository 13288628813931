import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Viewproof } from "../Viewproof/Viewproof";
import { Login } from "@mui/icons-material";

const TransactionModal = ({
  showModal,
  handleClose,
  selectedRow,
  handleMaualstatusChange,
  handleSubmit,
  currentpage,
  handleRejectCommentsChange,handleRejectReasonChange
}) => {

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      dialogClassName="custom-modal"
      className="model-card"
    >
      <Modal.Body className="modal-body">
        {selectedRow && (
          <>
            <Viewproof
              rowData={selectedRow}
              onManualStatusChange={handleMaualstatusChange}
              onRejectReasonChange={handleRejectReasonChange}
              onRejectCommentsChange={handleRejectCommentsChange}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        {currentpage == "new" && (
          <Button
            variant="secondary"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        )}
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransactionModal;
