
import * as XLSX from "xlsx";

const exportManualExcel = (data, fileName) => {
  const exportData = data.map((item,index) => ({
    "S.No": index + 1,
    "Transaction Number": item.transactionId,
    "Customer ID": item.eiaNo,
    "Name": item.customerName,
    "Insurer": item.insurerName, 
    "Service Type": item.serviceType,
    "Created Date": item.createdDate,
    "Status": item.manualStatus,
  }));

  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  
  
  XLSX.writeFile(wb, fileName);
};

export default exportManualExcel;

export const exportApprovedExcel = (data, fileName) => {
  const exportData = data.map((item,index) => ({
    "S.No": index + 1,
    "Transaction Number": item.transactionId,
    "Customer ID": item.eiaNo,
    "Customer Name": item.customerName,
    "Insurer": item.insurerName, 
    "Service Type": item.serviceType,
    "Reviewed At":item.updatedDate,
    "Created Date": item.createdDate,
    "Status": item.manualStatus,
  }));

  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  
  
  XLSX.writeFile(wb, fileName);
};
export const exportRejectedExcel = (data, fileName) => {
  const exportData = data.map((item,index) => ({
    "S.No": index + 1,
    "Transaction Number": item.transactionId,
    "Customer ID": item.eiaNo,
    "Name": item.customerName,
    "Insurer": item.insurer, 
    "Service Type": item.serviceType,
    "Created Date": item.createdDate,
    "Status": item.manualStatus,
    "Reviewed At":item.updatedDate,
    "Rejection Reason":item.rejectReason,
    "Rejection Comments": item.rejectComments,
  }));

  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  
  
  XLSX.writeFile(wb, fileName);
};

export const exportConsolidatedlog = (data, fileName) => {
  const exportData = data.map((item,index) => ({
    "S.No": index + 1,
    "Transaction Number": item.transactionId,
    "Customer ID": item.customerId,
    "Name": item.customerName,
    "Mobile Number":item.mobile,
    "Email":item.email,
    "Service Type": item.serviceType,
    "ProofType":item.documentType,
    "Current Details":item.currentDetails,
    "Ocr/New Details":item.newDetails,
    "Status Updated At":item.statusUpdatedAt,
    "Initiated At":item.initiatedAt,
    "Completed At":item.completedAt,
    "Image Link":item.imageUrl,
    "API requested at":"",
    "API responded at":"",
    "API connection status":"",
    "Disconnect reason":"",
    "Service update status":item.serviceUpdateStatus,
    "Service update failure reason":"",
    "Response duration":"",
  }));

  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  
  
  XLSX.writeFile(wb, fileName);
};


export const exportContactlog = (data, fileName) => {
  const exportData = data.map((item,index) => ({
    "S.No": index + 1,
    "Transaction Number": item.transactionId,
    "Customer ID": item.customerId,
    "Name": item.customerName,
    "Mobile Number":item.mobile,
    "Email":item.email,
    "Insurer": item.insurer, 
    "Service Type": item.serviceType,
    "Verification Status":item.status,
    "Screen Last Visited":"",
    "Activity":"",
    "Initiated At":item.initiatedAt,
    "Completed At":item.completedAt,
  }));

  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  
  
  XLSX.writeFile(wb, fileName);
};
export const exportActionlog = (data, fileName) => {
  const exportData = data.map((item,index) => ({
    "S.No": index + 1,
    "Transaction Number": item.transactionId,
    "Customer ID": item.customerId,
    "Name": item.customerName,
    "Insurer": item.insurer, 
    "Service Type": item.serviceType,
    "Updated On":item.completedAt,
    "Service":item.serviceType,
    "Field Name":item.fieldName,
    "Original Value":item.originalValue,
    "	New Value":item.newValue,
  }));

  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  
  
  XLSX.writeFile(wb, fileName);
};
export const exportIncompletelog = (data, fileName) => {
  const exportData = data.map((item,index) => ({
    "S.No": index + 1,
    "Transaction Number": item.transactionId,
    "Customer ID": item.customerId,
    "Name": item.customerName,
    "Insurer": item.insurer, 
    "Service Type": item.serviceType,
    "Initiated At": item.createdAt,
    "Verification Status": item.status,
  }));

  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  
  
  XLSX.writeFile(wb, fileName);
};
export const exportTransactionlog = (data, fileName) => {
  const exportData = data.map((item,index) => ({
    "S.No": index + 1,
    "Transaction Number": item.transactionId,
    "Customer ID": item.customerId,
    "Name": item.customerName,
    "Mobile":item.mobile,
    "Email":item.email,
    "Insurer": item.insurer, 
    "Service Type": item.serviceType,
    "Verification Status":item.status,
    "Manual Status":item.manualStatus,
    "Screen Last Visited":"",
    "Activity":"",
    "Initiated At":item.initiatedAt,
    "Completed At":item.completedAt
  }));

  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  
  
  XLSX.writeFile(wb, fileName);
};



