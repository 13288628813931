import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Navbar from "../Header/Navbar";
import {
  ROUTER_URL,
  LABEL_NAME,
  exportAsExcel,
  SERVICE_TYPE,
  POLICY_TYPE,
} from "../Common/constant";
import Select from "react-select";
import ActionButton from "../Common/ActionButton";
import { useNavigate } from "react-router-dom";
import { RotateSpinner } from "react-spinners-kit";
import { v4 as uuid } from "uuid";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "./style.css";

export default function Poc({ isPOC }) {
  const POC_LIST_COLUMNS = [
    { id: "Sl_No", label: "S.No", minWidth: 50 },
    { id: "eiaNo", label: "eIA account number", minWidth: 100 },
    { id: "policyNo", label: "Policy Number", minWidth: 100 },
    { id: "insurerCode", label: "Insurer Code", minWidth: 100 },
    { id: "insurerName", label: "Insurer Name", minWidth: 100 },
    { id: "uploadReqDate", label: "POC request date", minWidth: 100 },
    { id: "uploadReqStatus", label: "POC request status", minWidth: 100 },
    { id: "uploadResDate", label: "POC Response date", minWidth: 100 },
    { id: "uploadResStatus", label: "POC response status", minWidth: 100 },
    { id: "responseFileName", label: "POC response file name", minWidth: 100 },
    { id: "processedDate", label: "POC imported date", minWidth: 100 },
    { id: "processedStatus", label: "Policy conversion status", minWidth: 100 },
    { id: "rejectionReason", label: "POC rejection reason", minWidth: 100 },
    { id: "importedBy", label: "Imported By", minWidth: 100 },
    { id: "policyDataType", label: "Policy Data type", minWidth: 100 },
    { id: "serviceType", label: "Service Type", minWidth: 100 },

    // { id: 'reqUploadRefNo', label: 'Request Upload Ref No', minWidth: 100 },
    // { id: 'resUploadRefNo', label: 'Response Upload Ref No', minWidth: 100 },
    // { id: 'errUploadRefNo', label: 'Error Upload Ref No', minWidth: 100 },
    // { id: 'userRefId', label: 'User Ref Id', minWidth: 100 },
    // { id: 'requestFileName', label: 'Request File Name', minWidth: 100 },
    // { id: 'errFileName', label: 'Error File Name', minWidth: 100 },
    // { id: 'errCode', label: 'Error Code', minWidth: 100 },
    // { id: 'errDescription', label: 'Error Description', minWidth: 100 },
    // {
    //     id: 'remarks',
    //     label: 'Remarks',
    //     minWidth: 100,
    //     align: 'right',
    // }
  ];

  const INSURER_COLUMNS = [
    { id: "Sl_No", label: "S.No", minWidth: 50 },
    // { id: 'uploadRefNo', label: 'Upload Ref No', minWidth: 100 },
    // { id: 'userRefId', label: 'User Ref Id', minWidth: 100 },
    { id: "requestType", label: "Service Type", minWidth: 100 },
    { id: "insurerCode", label: "Insurer Code", minWidth: 100 },
    { id: "insurerName", label: "Insurer Name", minWidth: 100 },
    { id: "fileId", label: "File ID", minWidth: 100 },
    { id: "fileName", label: "File Name", minWidth: 100 },
    { id: "dateOfEntry", label: "Date of Entry", minWidth: 100 },
    { id: "numOfRec", label: "No.of records", minWidth: 100 },
    { id: "typeOfFile", label: "Policy type", minWidth: 100 },
    { id: "folderPath", label: "Folder Path", minWidth: 100 },
    { id: "individualBulk", label: "Policy Data type", minWidth: 100 },
    { id: "reqResErr", label: "File type", minWidth: 100 },
    {
      id: "remarks",
      label: "Remarks",
      minWidth: 170,
      align: "right",
      // format: (value) => value.toLocaleString('en-US'),
      // format: (value) => value.toFixed(2),
    },
  ];

  const columns = isPOC ? POC_LIST_COLUMNS : INSURER_COLUMNS;

  const [insurerCode, setInsurerCode] = useState("");
  const [insurerName, setInsurerName] = useState("");
  const [refNum, setRefNum] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [insurerData, setInsurerData] = useState([]);
  const [selectedInsurer, setSelectedInsurer] = useState("Select");

  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState("");
  const [showTable, setShowTable] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let options = insurerData.map((insurer) => ({
    value: `${insurer.Code}/${insurer.Description}`,
    label: `${insurer.Code}/${insurer.Description}`,
  }));

  options = [{ value: "Select", label: "Select" }, ...options];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "398px", // Set your desired width
      height: "10px", // Set your desired height
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black", // Set your desired text color
      backgroundColor: state.isSelected ? "blue" : "white", // Set your desired background color when selected
    }),
  };

  const todayDate = new Date().toISOString().split("T")[0];

  const navigate = useNavigate();

  const handleInsurerSelect = (selectedValue) => {
    // Parse selectedValue to get insurer code and description
    const [code, description] = selectedValue.split("/");

    // Do something with code and description
    setInsurerCode(code);
    console.log("Selected Description:", description);

    // Set the selected insurer state if needed
    setSelectedInsurer(selectedValue);
  };

  //Action Fucntion start from Here

  const BaseUrl = process.env.REACT_APP_API_URL;
  const apiUrl = BaseUrl + "/riInsurerBulkService/v1/ListInsurerWithLogo";

  const clickExport = () => {
    exportAsExcel("POCList.xlsx", responseData);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            txnId: uuid(),
            timestamp: "2023-07-25T09:56:20.501636500",
            Source: {
              appType: "MOBILE",
              osVersion: "11",
              deviceId: "",
              deviceName: "Mymobile 52",
              deviceOS: "Android",
              appVersion: "2.0",
              clientIp: "0.0.0.0",
              origin: "",
            },
          }),
        });

        const data = await response.json();

        if (data && data.MasterData) {
          setInsurerData(data.MasterData);
          // console.log(insurerData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs once when the component mounts

  const backClick = () => {
    navigate("/menudisplaypage");
  };

  const resetClick = (e) => {
    e.preventDefault();
    setSelectedInsurer("Select");
    setInsurerCode("");
    setInsurerName("");
    setRefNum("");
    setFromDate("");
    setToDate("");
    setShowTable(false);
    setLoading(false);
    setTransactionStatus("");
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    // const formattedFromDate = formatDate(fromDate);

    setLoading(true);
    setShowTable(false);

    // const formattedToDate = formatDate(toDate);
    if (
      !fromDate ||
      !toDate ||
      selectedInsurer === "Select" ||
      (isPOC ? !transactionStatus : "")
    ) {
      alert("Please fill in all mandatory fields");
      setLoading(false);
      setShowTable(false);
      return;
    }
    const formattedFromDate = new Date(fromDate).toLocaleDateString("en-GB"); // Assumes 'toDate' is in the format "YYYY-MM-DD"

    const formattedToDate = new Date(toDate).toLocaleDateString("en-GB"); // Assumes 'toDate' is in the format "YYYY-MM-DD"

    const BaseUrl = process.env.REACT_APP_API_URL;

    const EndPoint = isPOC
      ? "/riInsurerBulkService/v1/SFTPUploadLogs"
      : "/riInsurerBulkService/v1/SFTPUploadList";

    try {
      const response = await fetch(`${BaseUrl}${EndPoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          txnId: "9e5adbbe-39ae-72e8-a8d7-0289147bf002",
          timestamp: "2023-11-20T16:33:25.387994200",
          Source: {
            appType: "WEB",
            osVersion: "11",
            deviceId: "",
            deviceName: "Windows108",
            deviceOS: "Windows",
            appVersion: "1.0",
            clientIp: "0.0.0.0",
            origin: "RI",
          },
          Customer: {
            insurerCode: insurerCode,
            uploadRefNo: "1",
            fromDate: formattedFromDate,
            toDate: formattedToDate,
            trxnStatus: transactionStatus,
          },
        }),
      });
      if (response.ok) {
        setLoading(false);
        let jsonResponse = await response.json();
        jsonResponse = isPOC
          ? jsonResponse.SftpUploadLogs
          : jsonResponse.SftpUploadList;

        //conversion start here
        let convertedData = [];
        jsonResponse.forEach((element, index) => {
          let temp = {};
          // temp["Sl No"] = "0";
          columns.forEach((column) => {
            if (column.id === "Sl_No") {
              temp[column.label] = index + 1;
            } else if (
              column.id === "requestType" ||
              column.id === "serviceType"
            ) {
              temp[column.label] = SERVICE_TYPE[element[column.id]];
            } else if (column.id === "policyType") {
              temp[column.label] = POLICY_TYPE[element[column.id]];
            } else {
              temp[column.label] = element[column.id];
            }
          });
          convertedData.push(temp);
          temp = {};
        });
        setResponseData(convertedData);
        if (convertedData.length > 0) {
          setShowTable(true);
        } else {
          setLoading(false);
          // alert("No Data Found for given input.")
        }
      } else {
        // Handle errors
        setLoading(false);
        const errorResponse = await response.json();
        if (errorResponse?.displayMessage || errorResponse?.errorDescription) {
          alert(
            `Error: ${
              errorResponse.displayMessage !== undefined
                ? errorResponse.displayMessage
                : errorResponse.errorDescription
            }`
          );
        } 
      }
    } catch (error) {
      // Handle network errors or any other exceptions
      alert(`Error: ${error.message}`);
    }
  };

  //string to plaintext download

  function downloadTextFile(textContent, fileName) {
    const blob = new Blob([textContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    let a =
      document.getElementById("download-link") || document.createElement("a");
    a.id = "download-link";
    a.href = url;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(url);
  }

  const handleClickPlain = (requestXML, fileName) => {
    const xmlString = requestXML;

    // Use the XML string directly without parsing it as HTML
    downloadTextFile(xmlString, `${fileName}`);
  };

  return (
    <div>
      <Header />
      <div className="MenuPage">
        <Navbar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      </div>
      <div className="app-insurer">
        <div>
          <h1 className="heading">
            {isPOC ?  ROUTER_URL.POC_STATUS_HISTORY : ROUTER_URL.POC_UPLOAD_LIST }
          </h1>
        </div>

        {/* Add your text fields and button for user input */}
        <div className="row col-12">
          <div className="col-12">
            <form method="post">
              <div class="row">
                <div className={isPOC ? "col-xs-12 col-sm-2  username" : "col-xs-12  col-sm-2 username"}>
                  <div>
                    <label className="label_cls_poc">
                      {LABEL_NAME.INSURER_CODE_NAME}
                    </label>
                  </div>
                  <div class="" style={{ width: "100%" }}>
                    <Select
                      className="inputboxvu_insure"
                      style={{ padding: "4px" }}
                      value={{ value: selectedInsurer, label: selectedInsurer }}
                      options={options}
                      onChange={(selectedOption) =>
                        handleInsurerSelect(selectedOption.value)
                      }
                      placeholder="Select an insurer"
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className="username col-xs-12 col-sm-2">
                  <label className="label_cls">{LABEL_NAME.FROM_DATE}</label>
                  <div class="">
                    <input
                      type="date"
                      name="fromDate"
                      value={fromDate}
                      className="inputboxvu_insure form-control"
                      max={todayDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="username  col-xs-12 col-sm-2">
                  <label className="label_cls">{LABEL_NAME.TO_DATE}</label>
                  <div class="">
                    <input
                      type="date"
                      name="toDate"
                      value={toDate}
                      className="inputboxvu_insure form-control"
                      max={todayDate}
                      onChange={(e) => setToDate(e.target.value)}
                      required
                    />
                  </div>
                </div>
                {isPOC && (
                  <div className="username col-2 status-card col-sm-2">
                    <label className="label_cls">{LABEL_NAME.STATUS}</label>
                    <div>
                      <select
                        className="status-container"
                        value={transactionStatus}
                        onChange={(e) => setTransactionStatus(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="SUCCESS">SUCCESS</option>
                        <option value="PENDING">PENDING</option>
                        <option value="FAILURE">FAILURE</option>
                      </select>
                    </div>
                  </div>
                )}

                <div className="username  col-xs-12 col-sm-4">
                  <ActionButton
                    handleBack={backClick}
                    handleReset={resetClick}
                    handleSubmit={handleButtonClick}
                  />
                </div>
                <p className="note">
                  {" "}
                  Note:{" "}
                  {isPOC ? LABEL_NAME.POCLIST_NOTE : LABEL_NAME.INSURER_NOTE}
                </p>
              </div>
            </form>
            <div className="loading">
              <RotateSpinner size={70} color="#f99117" loading={loading} />
            </div>
          </div>
          <div className="col-12">
            <div className="item-insurer">
              <div className="tableContainer-insurer">
                {showTable && (
                  <div className="">
                    <button onClick={clickExport} className="export-button">
                      Export as Excel
                    </button>
                    {/* <Button variant="outlined">Outlined</Button> */}
                    <div class="">
                      <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {responseData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                  // console.log(row, index);
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.code}
                                    >
                                      {columns.map((column) => {
                                        const value = row[column.label];
                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {/* {column.format && typeof value === 'number'
                                                  ? column.format(value)
                                                  : value} */}
                                            {value}
                                            {!isPOC &&
                                            column.id === "reqResErr" &&
                                            row.reqResErr === "Request" &&
                                            row.requestXML !== "" ? (
                                              <Button
                                                variant="contained"
                                                className="request-button ml-2"
                                                onClick={() =>
                                                  handleClickPlain(
                                                    row.requestXML,
                                                    row.fileName
                                                  )
                                                }
                                              >
                                                Download
                                              </Button>
                                            ) : (
                                              ""
                                            )}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 100]}
                          component="div"
                          count={responseData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
