import './style.css';

import React from 'react';

import { slide as Menu } from 'react-burger-menu';
import {
  Link,
  useNavigate,
} from 'react-router-dom';

import { ROUTER_URL } from '../Common/constant';
import { useAnalytics } from '../useAnalytics.ts';

const Navbar = (MenuFlag) => {
  const navigate = useNavigate();

  const logout = () => {
    navigate("/");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("token_expires_at");
  };

  const value = MenuFlag.MenuFlag == "Menu" ? true : false;

  // const location = useLocation();
  // console.log(location);
  useAnalytics();
  return (
    <div className="MenuPage">
      <div></div>
      {/* <img src= 'LogoName.png'/> */}

      <Menu isOpen={value}>
        <div>{"Policy Details".toUpperCase()}</div>
        <Link to="/insurerdetails" className="link-text">
          {ROUTER_URL.POC_UPLOAD_LIST}
        </Link>
        <Link to="/poclist" className="link-text">
          {ROUTER_URL.POC_STATUS_HISTORY}
        </Link>
        <Link to="/policyenquiry" className="link-text">
          {ROUTER_URL.POLICY_ENQUIRY}
        </Link>
        <Link to="/policydataverification" className="link-text">
          {ROUTER_URL.POLICY_DATA_VERIFICATION}
        </Link>
        <Link to="/policydetailsbulk" className="link-text">
          {ROUTER_URL.POLICY_IMPORT}
        </Link>
        {/* <Link to="/panvalidation" className="link-text">
          PAN VALIDATION
        </Link> */}
        {/* </div>
        )} */}
        <div>{"Reports".toUpperCase()}</div>
        <Link to="/endorsement" className="link-text">
          {ROUTER_URL.ENDORSEMENT}
        </Link>
        <Link to="/renewal" className="link-text">
          {ROUTER_URL.RENEWAL}
        </Link>
        <Link to="/policyreport" className="link-text">
          {ROUTER_URL.POLICY_REPORT}
        </Link>
        <Link to="/insamatch" className="link-text">
          {ROUTER_URL.INSA_MATCH}
        </Link>

        {/* <Link to='/Support' className='link-text'>{ROUTER_URL.SUPPORT}</Link> */}
        <div>Back-Office</div>
        <Link to="/dashboard" className="link-text">
          POLICY GENIE
        </Link>

        {/* </div>
        )} */}
        <div className="log-out-card"></div>
        <button className="logout-button" onClick={logout}>
          Logout
        </button>
        <p className="version">Version 1.0</p>
      </Menu>
    </div>
  );
};
export default Navbar;
