
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./bocomponent.css"; 
import { Battery50Outlined } from "@mui/icons-material";

const FiltersComponent = ({
  createdFrom,
  createdTo,
  updateFrom,
  updateTo,
  handleCreatedFromChange,
  handleCreatedToChange,
  handleUpdateFromChange,
  handleUpdateToChange,
  serviceTypeOptions,
  statusTypeOption,
  queueOption,
  serviceTypeFilter,
  handleServiceTypeFilter,
  insurerOption,
  insurerFilter,
  handleInsurerFilter,
  handleStatusFilter,
  handleQueueFilter,
  handleReset,
  handleRefresh,
  contactlogs,
  handleExport,
  currentPage,
  statusFilter,
  queueFilter
}) => {
  return (
    <div className="filtercontainer"
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
    }}
    >
      <div style={{ display: "flex", flexDirection: "column",justifyContent:"space-around" }}>
        <DatePicker
          className="filterDatePicker"
          selected={createdFrom}
          onChange={handleCreatedFromChange}
          selectsStart
          startDate={createdFrom}
          endDate={createdTo}
          maxDate={new Date()}
          placeholderText="Created From"
          dateFormat="dd-MM-yyyy"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          todayButton="Today"
        />
        <DatePicker
          className="filterDatePicker"
          selected={createdTo}
          onChange={handleCreatedToChange}
          selectsEnd
          startDate={createdFrom}
          endDate={createdTo}
          minDate={createdFrom}
          maxDate={new Date()}
          placeholderText="Created To"
          dateFormat="dd-MM-yyyy"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          todayButton="Today"
        />
      </div>
      {currentPage !== "NewTransaction" && currentPage !== "contactlog" && currentPage !== "boactionlog" && currentPage !== "consolidatedreport" && currentPage !=="transactionlog" && (currentPage !== "incompletecases") ? (
      <div style={{ display: "flex", flexDirection: "column",justifyContent:"space-around"}}>
        <DatePicker
          className="filterDatePicker"
          selected={updateFrom}
          onChange={handleUpdateFromChange}
          selectsStart
          startDate={updateFrom}
          endDate={updateTo}
          maxDate={new Date()}
          minDate={createdTo ? createdFrom : null} 
          placeholderText="Updated From"
          dateFormat="dd-MM-yyyy"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          todayButton="Today"
        />
        <DatePicker
          className="filterDatePicker"
          selected={updateTo}
          onChange={handleUpdateToChange}
          selectsEnd
          startDate={updateFrom}
          endDate={updateTo}
          minDate={updateFrom}
          maxDate={new Date()}
          placeholderText="Updated To"
          dateFormat="dd-MM-yyyy"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          todayButton="Today"
      
        />
      </div>
      ):<></>}

      {/* Service type and insurer filters */}
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
      <select
  className="filterSelect"
  value={serviceTypeFilter}
  onChange={(e) => handleServiceTypeFilter(e.target.value)}
  placeholderText="Service options"
  size="sm"
>
  {/* Options for service types */}
  <option value="">Select Service Type</option>
  {serviceTypeOptions.map((service, index) => (
    <option key={index} value={service}>
      {service}
    </option>
  ))}
</select>
        <select
          className="filterSelect"
          value={insurerFilter}
          onChange={(e) => handleInsurerFilter(e.target.value)}
          placeholderText="Insurer"
        >
         
          <option value="">Select Insurer</option>
          {insurerOption.map((insurer, index) => (
            <option key={index} value={insurer}>
              {insurer}
            </option>
          ))}
        </select>
      </div>
      {currentPage === "transactionlog" ? (<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
        <select
          className="filterSelect"
          value={statusFilter}
          onChange={(e) => handleStatusFilter(e.target.value)}
          placeholderText="Status"
          size="sm"
        >
          {/* Options for service types */}
          <option value="">Status</option>
          {statusTypeOption.map((status, index) => (
            <option key={index} value={status}>
              {status}
            </option>
          ))}
        </select>
        <select
          className="filterSelect"
          value={queueFilter}
          onChange={(e) => handleQueueFilter(e.target.value)}
          placeholderText="Manual Queue (MQ) Status"
        >
         
          <option value="">Manual Queue (MQ) Status</option>
          {queueOption.map((queue, index) => (
            <option key={index} value={queue}>
              {queue}
            </option>
          ))}
        </select>
      </div>) :<></>}
      {currentPage === "contactlog" ? (<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
        <select
          className="filterSelect contactlog"
          value={statusFilter}
          onChange={(e) => handleStatusFilter(e.target.value)}
          placeholderText="Status"
          size="sm"
        >
          {/* Options for service types */}
          <option value="">Status</option>
          {contactlogs.map((service, index) => (
            <option key={index} value={service}>
              {service}
            </option>
          ))}
        </select>
       
      </div>) :<></>}
     
     
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around",marginTop:"14px"}} className="">
        <div> 
          <button className="fa-solid fa-rectangle-xmark icon " onClick={handleReset}></button>
          <p className="icon-text">RESET</p>
        </div>
        <div>
          <button className="fa-solid fa-arrows-rotate icon" onClick={handleRefresh}></button>
          <p className="icon-text">REFRESH</p>
        </div>
        <div>
          <button className="fa-solid fa-file-export icon" onClick={handleExport}></button>
          <p className="icon-text">EXPORT</p>
        </div>
  
      </div>
    </div>
  );
};

export default FiltersComponent;
