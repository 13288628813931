import Navbar from "./Header/Navbar";

import React from 'react'
import '../src/index.css'
import Header from "./Header/Header";


const MenuDisplayPage = () => {
  return (
    <div className="MenuPage1">
      <Header />
      <div className="MenuPage">
        <Navbar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} MenuFlag="Menu"  />
      </div>
      <div className="menu-display"></div>
    </div> 
  )
}

export default MenuDisplayPage
