import axios from "axios";

export const inProgress = async (
  Status,
  fromDate,
  toDate,
  updatefromDate,
  updatetoDate
) => {
  const BaseUrl = process.env.REACT_APP_API_URL;
  const apiUrl = BaseUrl + "/riCustomerManagement/v1/QueueManagementPg";

  try {
    const response = await axios.post(
      apiUrl,
      {
        flag: Status,
        fromDate: fromDate,
        toDate: toDate,
        updatedFrom: updatefromDate,
        updatedTo: updatetoDate,
      },
      {
        timeout: 25000000,
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer 0Kf2qShcGXK026XxsbXkEH89diE",

        },
      }
    );

    const jsonData = response.data;
    // console.log(jsonData, jsonData.errorCode, "data from API");
    return jsonData;
  } catch (error) {
    // console.log("Error submitting data:==========>", error);
    console.error("Error submitting data:", error);
    return [];
  }
};

const manualQueue = async (
  setStatus,
  fromDate,
  toDate,
  updatefromDate,
  updatetoDate,
  setData,
  setAddressCount,
  setNeftCount,
  setIsLoading,
  setSuccessMessage,
  setErrorMessage,
  handleCloseAfterDelay
) => {
  setIsLoading(true);

  try {
    const manualData = await inProgress(
      setStatus,
      fromDate,
      toDate,
      updatefromDate,
      updatetoDate
    );
    const newData = manualData.queueManagement;
    // console.log(newData, "============manual data");

    if (Array.isArray(newData) && newData.length > 0) {
      if (setStatus == "New") {
        newData.sort(
          (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
        );
      } else {
        newData.sort(
          (a, b) => new Date(b.updatedDate) - new Date(a.updatedDate)
        );
      }
      setSuccessMessage("Data Fetched Successfully.");
      handleCloseAfterDelay(setSuccessMessage);
      setData(newData);

      const addressCount = newData.filter(
        (item) => item.serviceType === "ADDRESS" || item.serviceType === "Address"
      ).length;
      const neftCount = newData.filter(
        (item) => item.serviceType === "NEFT" || item.serviceType === "Neft"
      ).length;

      setAddressCount(addressCount);
      setNeftCount(neftCount);
    } else {
      setErrorMessage("No New Transactions Available!");
      handleCloseAfterDelay(setErrorMessage);
      console.error("Data fetched is not an array:", manualData);
      setData([]); // Update state with an empty array
      setAddressCount(0); // Reset counts
      setNeftCount(0);
    }
  } catch (error) {
    setErrorMessage("Error fetching data!");
    handleCloseAfterDelay(setErrorMessage);
    console.error("Error fetching data:", error);
  } finally {
    setIsLoading(false);
  }
};

export default manualQueue;
