import React from 'react';
import { Link } from 'react-router-dom';
import "./bocomponent.css"; 
import logo from "./logo.svg"
import HomeIcon from '@mui/icons-material/Home';

import { useNavigate } from 'react-router-dom';


const BoHeader = () => {

const navigate = useNavigate()

  const backFunc = ()=>{
    navigate("/menudisplaypage")
  }


    return (
      <header className='header-s'>
        <div className="header-content">
          <h1>Backoffice</h1>
          <img alt='img' src={logo} className="logo3" width="50px" height='50px' />
          <div style={{display:"flex",flexDirection:"row"}}>
            {/* <h1>User Id:<span style={{color:"#f99117"}}>{sessionStorage.getItem("userId")}</span></h1> */}
          <button className='home-icon' onClick={backFunc}><HomeIcon  fontSize="large" className='home-icon' /></button>
          </div>
        </div>
      </header>
    );
  };
  
  export default BoHeader;