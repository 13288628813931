import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import {Viewproof} from "./Dashboard/Viewproof/Viewproof"
import NewTransaction from "./New/new";
import ApprovedTransactions from "./Approved/approved";
import RejectedTransactions from "./Rejected/Rejected";

// import NewTransaction from './New/new';

function BoDashboard() {
  return (
    <div>
      <NewTransaction />
    </div>
  );
}

export default BoDashboard;
