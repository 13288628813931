import React from 'react'
import { useLocation } from 'react-router-dom'
// import ga4 from 'react-ga4'

import * as analytics from './ga4.ts';

export function useAnalytics() {
    let location = useLocation()
    // console.log(location)
    React.useEffect(() => {
        analytics.init()
    }, [])

    React.useEffect(() => {
        const path = location.pathname + location.search
        // console.log(path);
        analytics.sendPageview(path)
    }, [location])
}

// export default useAnalytics
