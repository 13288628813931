import React, { useState, useEffect } from "react";
import {Pagination} from "react-bootstrap";
import manualQueue,{ inProgress } from "../API/getQueue";
import "./reject.css";
import BoSidebar from "../Bocomponents/sideNav";
import Loading from "../Bocomponents/Loader";
import BoHeader from "../Bocomponents/Header";
import "react-datepicker/dist/react-datepicker.css";
import {exportRejectedExcel} from "../Utilities/excel";
import FiltersComponent from "../Bocomponents/filters";
import TransactionModal from "../Bocomponents/modal";
import TransactionTable from "../Bocomponents/Table";
import CustomToast from "../Bocomponents/Alert";
import {formatDate, oneYearAgo, oneMonthAgo} from "../Utilities/dateformater.jsx"

const RejectedTransactions = () => {
  const Status = "Rejected";
  const currentDate = new Date(); 
  const oneYearAgoDate = oneYearAgo(currentDate);
  const oneMonthAgoDate=oneMonthAgo(currentDate)
 
  const fromDateInitial = formatDate(oneMonthAgoDate);
  const toDateInitial=formatDate(currentDate)
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(fromDateInitial);
  const [toDate, setToDate] = useState(toDateInitial);


  const updatefromDateInitial = formatDate(oneYearAgoDate);
  const updatetoDateInitial=formatDate(currentDate)


  const [updatefromDate, setUpdateFromDate] = useState("");
  const [updatetoDate, setUpdateToDate] = useState("");




  useEffect(() => {
    manualQueue(
      Status,
      fromDate,
      toDate,
      updatefromDate,
      updatetoDate,
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
  }, []);

  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dateFilter, setDateFilter] = useState("");
  const [serviceTypeFilter, setServiceTypeFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [createdFrom, setCreatedFrom] = useState(null);
  const [createdTo, setCreatedTo] = useState(null);
  const [updateFrom, setUpdateFrom] = useState(null);
  const [updateTo, setUpdateTo] = useState(null);
  const [insurerFilter, setInsurerFilter] = useState("");
  const [addressCount, setAddressCount] = useState(0);
  const [neftCount, setNeftCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  //filters
  const handleCreatedFromChange = (date) => {
    // console.log("calling here");
    setCreatedFrom(date);
    const formattedDate = formatDate(date)
    // console.log(formattedDate, "=====created From");

    const toDateToUse = createdTo ? formatDate(createdTo) : toDateInitial;


    if (!createdTo) {
      setToDate(toDateInitial);
    }
    const fromupDateToUse = updateFrom ? formatDate(updateFrom) : "";
    const toupDateToUse = updateTo ? formatDate(updateTo) : "";
   
    manualQueue(
      Status,
      formattedDate,
      toDateToUse,
      fromupDateToUse,
      toupDateToUse,
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );

  };



  const handleCreatedToChange = (date) => {
    // console.log(fromDate,"fromdate======>");
    setCreatedTo(date);
    const formattedDate = formatDate(date);
    // console.log(formattedDate,"=====created to");
    const fromDateToUse = createdFrom ? formatDate(createdFrom) : fromDateInitial;
    if (!createdFrom) {
      setToDate(formattedDate);

    }
    const fromupDateToUse = updateFrom ? formatDate(updateFrom) : "";
    const toupDateToUse = updateTo ? formatDate(updateTo) : "";
   
      manualQueue(
        Status,
        fromDateToUse,
        formattedDate,
        fromupDateToUse,
        toupDateToUse,
        setData,
        setAddressCount,
        setNeftCount,
        setIsLoading,
        setSuccessMessage,
        setErrorMessage,
        handleCloseAfterDelay
      );
    
  };

  const handleUpdateFromChange = (date) => {
    setUpdateFrom(date);
    const formattedDate = formatDate(date);
    const fromcreateDateToUse = createdFrom ? formatDate(createdFrom) : "";
    const tocreateDateToUse = createdTo ? formatDate(createdTo) : "";
    const toDateToUse = updateTo ? formatDate(updateTo) : toDateInitial;
manualQueue(
        Status,
        fromcreateDateToUse,
        tocreateDateToUse,
        formattedDate,
        toDateToUse,
        setData,
        setAddressCount,
        setNeftCount,
        setIsLoading,
        setSuccessMessage,
        setErrorMessage,
        handleCloseAfterDelay
    );
};



  
  const handleUpdateToChange = (date) => {
    setUpdateTo(date);
    const formattedDate = formatDate(date);
    const fromDateToUse = createdFrom ? formatDate(createdFrom) : "";
    const toDateToUse = createdTo ? formatDate(createdTo) : "";
    const fromupDateToUse = updateFrom ? formatDate(updateFrom) : formatDate(oneYearAgoDate);
    manualQueue(
      Status,
      fromDateToUse,
      toDateToUse,
      fromupDateToUse,
      formattedDate,
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
  };



  const serviceTypeOptions = ["ADDRESS", "NEFT"];
  const insurerOption = [
    "Reimagine",
    "Kenscio",
    "Yellow messenger",
    "CAMSRep",
    "MCAMSRep",
  ];
  const handleCloseAfterDelay = (setMessageFunction) => {
    setTimeout(() => {
      setMessageFunction("");
    }, 3000);
  };

  const handleRefresh = () => {
    let fromcreateDateToUse = createdFrom ? formatDate(createdFrom) : fromDateInitial;
    let tocreateDateToUse = createdTo ? formatDate(createdTo) : toDateInitial;
    let updaterefreshedFromDate = updateFrom ? formatDate(updateFrom) : "";
    let updaterefreshedToDate = updateTo ? formatDate(updateTo) : "";
  
    // If createdFrom and createdTo are set and updateFrom and updateTo are not set
    if (createdFrom && createdTo && !updateFrom && !updateTo) {
        fromcreateDateToUse = formatDate(createdFrom);
        tocreateDateToUse = formatDate(createdTo);
        updaterefreshedFromDate = "";
        updaterefreshedToDate = "";
    }
  
    // If updateFrom and updateTo are set and createdFrom and createdTo are not set
    if (updateFrom && updateTo && !createdFrom && !createdTo) {
        fromcreateDateToUse = "";
        tocreateDateToUse = "";
    }
  
    manualQueue(
        Status,
        fromcreateDateToUse,
        tocreateDateToUse,
        updaterefreshedFromDate,
        updaterefreshedToDate,
        setData,
        setAddressCount,
        setNeftCount,
        setIsLoading,
        setSuccessMessage,
        setErrorMessage,
        handleCloseAfterDelay
    );
  };
  //ecport to excel
  const handleExport = () => {
  
    const formattedDate = currentDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    const fileName = `Rejected-Transactions_${formattedDate}.xlsx`;

    const manualData = data.filter((item) => {
      const createdDate = new Date(item.createdDate);
      if (
        (createdFrom && createdDate < createdFrom) ||
        (createdTo && createdDate > createdTo)
      ) {
        return false;
      }

      if (serviceTypeFilter && item.serviceType !== serviceTypeFilter) {
        return false;
      }
      if (insurerFilter && item.insurerName !== insurerFilter) {
        return false;
      }

      return true;
    });

    exportRejectedExcel(manualData, fileName);
  };

  // Pagination
  const filteredServiceTypeData = serviceTypeFilter
  ? data.filter((item) => item.serviceType.toUpperCase() === serviceTypeFilter)
  : data;

const filteredInsurerData = insurerFilter
  ? filteredServiceTypeData.filter((item) => item.insurerName === insurerFilter)
  : filteredServiceTypeData;
  const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = filteredInsurerData.slice(indexOfFirstItem, indexOfLastItem);

// Pagination
const totalPages = Math.ceil(filteredInsurerData.length / itemsPerPage);
  const maxPagesToShow = 5;
  let startPage, endPage;
  
  if (totalPages <= maxPagesToShow) {
    // Less than or equal to 5 total pages, so show all
    startPage = 1;
    endPage = totalPages;
  } else {
    // More than 5 total pages, so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // Current page near the start
      startPage = 1;
      endPage = maxPagesToShow;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // Current page near the end
      startPage = totalPages - maxPagesToShow + 1;
      endPage = totalPages;
    } else {
      // Current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }
  
  // Helper function to generate page numbers
  const range = (start, end) => Array.from({ length: end - start + 1 }, (_, i) => start + i);
  
  const pageNumbers = range(startPage, endPage);


  // Modal handling
  const handleClose = () => setShowModal(false);

  const [enableRowClick, setEnableRowClick] = useState(true);
  const handleRowClick = (rowData) => {
    if (enableRowClick) {
      setSelectedRow(rowData);
      setShowModal(true);
    } 
  };

  // Functions to handle filters
  const handleDateFilter = (selectedDate) => {
    setDateFilter(selectedDate);
  };
  const handleServiceTypeFilter = (selectedServiceType) => {
    const formattedServiceType = selectedServiceType.toUpperCase();
    setServiceTypeFilter(formattedServiceType);
  };
  
  const handleInsurerFilter = (selectedInsurer) => {
    setInsurerFilter(selectedInsurer);
  };
  const handleReset = () => {
    setCreatedTo(null);
    setCreatedFrom(null);
    setUpdateFrom(null);
    setUpdateTo(null);
    setServiceTypeFilter("");
    setInsurerFilter("");
    handleRefresh();
    setUpdateFrom("");
    setUpdateTo("");
    setFromDate("");
    setToDate("");

    setUpdateFrom("")
    setUpdateTo("")
    setFromDate("")
    setToDate("")
    manualQueue(
      Status,
      fromDateInitial,
      toDateInitial,
      "",
      "",
      setData,
      setAddressCount,
      setNeftCount,
      setIsLoading,
      setSuccessMessage,
      setErrorMessage,
      handleCloseAfterDelay
    );
  };

  const visibleColumns = [
    "Transaction Number",
    "Customer ID",
    "Customer Name",
    "Insurer",
    "Service",
    "Created Date",
    "Reviewed At",
    "Manual Status",
    "Rejection Reason",
    "Rejection Comments",
  ];
  return (
    <div>
      {isLoading && (
       <Loading />
      )}
       {successMessage && (
        <CustomToast
          variant="success"
          message={successMessage}
          onClose={() => setSuccessMessage("")}
        />
      )}

      {errorMessage && (
        <CustomToast
          variant="danger"
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}
      <BoHeader />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <BoSidebar />
        <div className="tableContainer">
        <span style={{color:'#1b67cc', fontSize:'20px'}}>Policy Genie Back Office - Manual Queue - Rejected Status </span>
          <FiltersComponent
            createdFrom={createdFrom}
            createdTo={createdTo}
            updateFrom={updateFrom}
            updateTo={updateTo}
            handleUpdateFromChange={handleUpdateFromChange}
            handleUpdateToChange={handleUpdateToChange}
            handleCreatedFromChange={handleCreatedFromChange}
            handleCreatedToChange={handleCreatedToChange}
            serviceTypeOptions={serviceTypeOptions}
            serviceTypeFilter={serviceTypeFilter}
            handleServiceTypeFilter={handleServiceTypeFilter}
            insurerOption={insurerOption}
            insurerFilter={insurerFilter}
            handleInsurerFilter={handleInsurerFilter}
            handleReset={handleReset}
            handleRefresh={handleRefresh}
            handleExport={handleExport}
            currentPage="Reject"
          />
          <TransactionTable
            data={data}
            currentItems={currentItems}
            handleRowClick={handleRowClick}
            visibleColumns={visibleColumns}
          />

          <div>
            <p className="bottom-para">
              Total Entries- Address: {addressCount} | Neft: {neftCount}
            </p>
          </div>

          <Pagination className="paginationContainer">
  <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
  <Pagination.Prev
    onClick={() => setCurrentPage(currentPage - 1)}
    disabled={currentPage === 1}
  />
  {pageNumbers.map((page) => (
    <Pagination.Item
      key={page}
      active={page === currentPage}
      onClick={() => setCurrentPage(page)}
    >
      {page}
    </Pagination.Item>
  ))}
  <Pagination.Next
    onClick={() => setCurrentPage(currentPage + 1)}
    disabled={currentPage === totalPages}
  />
  <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
</Pagination>
          <TransactionModal
          className="custom-modal"
            showModal={showModal}
            handleClose={handleClose}
            isLoading={isLoading}
            selectedRow={selectedRow}
          
            
          />
        </div>
      </div>
    </div>
  );
}
export default RejectedTransactions