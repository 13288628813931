import React from 'react';
import Toast from 'react-bootstrap/Toast';
import 'bootstrap/dist/css/bootstrap.min.css'; 

function CustomToast({ variant, message, onClose }) {
  let icon = null;
  if (variant === 'success') {
    icon = <i class="fa-solid fa-circle-check"></i>;
  } else if (variant === 'danger') {
    icon = <i class="fa-solid fa-circle-exclamation"></i>;
  }
  return (
    <Toast
 className={`alert-message custom-toast show bg-${variant}`}
      bg={variant.toLowerCase()}
      onClose={onClose}
      delay={3000} 
      autohide
    >
      
      <Toast.Body className={variant === 'dark' && 'text-white'}>
        {icon}
        {message}
      </Toast.Body>
    </Toast>
  );
}

export default CustomToast;

