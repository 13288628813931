import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import "./style.css";
import * as XLSX from "xlsx";

import { v4 as uuid } from "uuid";

import axios from "axios";
import { RotateSpinner } from "react-spinners-kit";
import Header from "../Header/Header";
import Navbar from "../Header/Navbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import { Button } from "react-bootstrap";
import {
  array,
  bankDetails,
  communicationAddressDetails,
  permanentAddressDetails,
  nomineeDetails,
  nomineeDetailsMotor,
  MotorclaimDetails,
  VehicleDetails,
  AgentDetails,
  DeductibleDetails,
  claimDetails,
  premiumCalculationDetails,
  insurerDetails,
  HealthDetails,
  Addressvalue,
  ContactDetails,
  NomineeDetails,
  InsuredMembers,
} from "./Tables";
// console.log(ContactDetails, "-------------");

import { ROUTER_URL, LABEL_NAME } from "../Common/constant";
import ActionButton from "../Common/ActionButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function PolicyEnquiry(props) {
  const [policyDetails, setPolicyDetails] = useState(true);
  const [error, setError] = useState(null);

  const currentDate = new Date();

  // Format the date components individually
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = currentDate.getDate().toString().padStart(2, "0");
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const milliseconds = currentDate
    .getMilliseconds()
    .toString()
    .padStart(3, "0");

  // Combine the components into the desired format
  const timeStamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}00`;

  const [showTable, setShowTable] = useState(false);

  const toggleTable = () => {
    setShowTable(!showTable);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [errorMessages, setErrorMessages] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [value, setValue] = useState("");
  const [pass, setPass] = useState("");
  let isShowPOCList = false;
  const [list, setList] = useState(false);
  const [responseData, setResponseArrayData] = useState([]);
  const [data, setData] = React.useState([]);
  const [showPolicyDetails, setShowPolicyDetails] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const result = [];

  const policyEnquiryFun = () => {
    setShowPolicyDetails(!showPolicyDetails);
  };

  // New unique id
  const unique_id = uuid();

  // Get first 8 characters using slice
  const small_id = unique_id.slice(0, 8);

  const [EiaNo, setEiaNo] = useState("");
  const [pan, setPan] = useState("");
  const [appName, setAppName] = useState("");

  const [mobileNo, setMobileNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [policyNo, setPolicyNo] = useState("");
  const [policyType, setPolicyType] = useState("");
  const [bankData, setBankData] = useState("");

  const date = new Date();
  const formattedDate = date.toLocaleDateString("en-GB");

  const [showCustomer, setShowCustomer] = useState(true);
  const [showPermanentAddress, setShowPermanentAddress] = useState(true);
  const [showBank, setShowBank] = useState(true);
  const [loading, setLoading] = useState(false);
  const [responseArray, setResponseArray] = useState([]);
  const [policyTypeView, setPolicyTypeView] = useState("");
  const toggleSection = (section) => {
    switch (section) {
      case "customer":
        setShowCustomer(!showCustomer);
        break;
      case "permanentAddress":
        setShowPermanentAddress(!showPermanentAddress);
        break;
      case "bank":
        setShowBank(!showBank);
        break;
      default:
        break;
    }
  };
  const getArrowIcon = (show) => (show ? "fa-chevron-up" : "fa-chevron-down");

  const TableRow = ({ label, value }) => (
    <tr>
      <td
        style={{
          border: "1px solid black",
          padding: "10px",
          fontWeight: "bold",
        }}
      >
        {label}
      </td>
      <td style={{ border: "1px solid black", padding: "10px" }}>
        {typeof value === "object" ? (
          <pre>{JSON.stringify(value, null, 2)}</pre>
        ) : (
          <span>{value}</span>
        )}
      </td>
    </tr>
  );
  const PolicyDetailsTable = ({ title, content, HealthDetails }) => (
    <div>
      <h2>{title}</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {HealthDetails.map((key) => (
            <TableRow key={key} label={key} value={content[key]} />
          ))}
        </tbody>
      </table>
    </div>
  );
  const AddressDetailsTable = ({ title, content, Addressvalue }) => (
    <div>
      <h2>{title}</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {Addressvalue.map((key) => (
            <TableRow key={key} label={key} value={content[key]} />
          ))}
        </tbody>
      </table>
    </div>
  );
  const ContactDetailsTable = ({ title, content, ContactDetails }) => (
    <div>
      <h2>{title}</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {ContactDetails.map((key) => (
            <TableRow key={key} label={key} value={content[key]} />
          ))}
        </tbody>
      </table>
    </div>
  );

  const InsuredMembersTable = ({ title, content, InsuredMembers }) => (
    <div>
      <h2>{title}</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {InsuredMembers.map((key) => (
            <TableRow key={key} label={key} value={content[key]} />
          ))}
        </tbody>
      </table>
    </div>
  );
  const NomineeTable = ({ title, content, NomineeDetails }) => (
    <div>
      <h2>{title}</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {NomineeDetails.map((key) => (
            <TableRow key={key} label={key} value={content[key]} />
          ))}
        </tbody>
      </table>
    </div>
  );
  const NomineeTableMotor = ({ title, content, nomineeDetailsMotor }) => (
    <div>
      <h2>{title}</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {nomineeDetailsMotor.map((key) => (
            <TableRow key={key} label={key} value={content[key]} />
          ))}
        </tbody>
      </table>
    </div>
  );
  const VehicleTable = ({ title, content, VehicleDetails }) => (
    <div>
      <h2>{title}</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {VehicleDetails.map((key) => (
            <TableRow key={key} label={key} value={content[key]} />
          ))}
        </tbody>
      </table>
    </div>
  );

  const AgentTable = ({ title, content, AgentDetails }) => (
    <div>
      <h2>{title}</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {AgentDetails.map((key) => (
            <TableRow key={key} label={key} value={content[key]} />
          ))}
        </tbody>
      </table>
    </div>
  );

  const DeductibleTable = ({ title, content, DeductibleDetails }) => (
    <div>
      <h2>{title}</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {DeductibleDetails.map((key) => (
            <TableRow key={key} label={key} value={content[key]} />
          ))}
        </tbody>
      </table>
    </div>
  );

  const ClaimDetailsTable = ({ title, content, claimDetails }) => (
    <div>
      <h2>{title}</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {claimDetails.map((key) => (
            <TableRow key={key} label={key} value={content[key]} />
          ))}
        </tbody>
      </table>
    </div>
  );
  const MotorClaimDetailsTable = ({ title, content, MotorclaimDetails }) => (
    <div>
      <h2>{title}</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {MotorclaimDetails.map((key) => (
            <TableRow key={key} label={key} value={content[key]} />
          ))}
        </tbody>
      </table>
    </div>
  );
  const TableSection = ({ title, content, keysToShow }) => (
    <div>
      <h2>{title}</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {Object.entries(content).map(([key, value]) => (
            <TableRow key={key} label={key} value={value} />
          ))}
        </tbody>
      </table>
    </div>
  );
  const PermanentAddressTable = ({ permanentAddressData }) => (
    <div>
      <h2>Permanent Address</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {Object.entries(permanentAddressData).map(([key, value]) => (
            <TableRow key={key} label={key} value={value} />
          ))}
        </tbody>
      </table>
    </div>
  );

  const clearState = () => {
    setEiaNo("");
    setPan("");
    setMobileNo("");
    setEmailId("");
  };

  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Months are zero-based
    const year = dateObject.getFullYear();

    // Pad single-digit day and month with a leading zero
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  const getPolicyType = (e) => {
    return e == "H" ? "Health" : e == "M" ? "Motor" : "Life";
  };

  const handleButtonClick = async (e) => {
    // console.log("button clicked");

    e.preventDefault();

    if (policyType === "H") {
      setPolicyTypeView("HEALTH");
    } else if (policyType === "M") {
      setPolicyTypeView("MOTOR");
    } else {
      setPolicyTypeView("LIFE");
    }

    let regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    if (!regexEmail.test(emailId) && emailId !== "") {
      seterrorMessage("Please Enter valid Email");
      return;
    } else if (!regpan.test(pan) && pan !== "") {
      seterrorMessage("Please Enter valid PAN");
      return;
    } else {
      seterrorMessage("");
    }

    if (!policyType && (!policyNo || !EiaNo)) {
      alert("Either Policy No OR eIA NO and Policy Type should be Mandatory");
      setLoading(false);

      return;
    }
    const formattedFromDate = new Date(mobileNo).toLocaleDateString("en-GB"); // Assumes 'emailId' is in the format "YYYY-MM-DD"
    setLoading(true);

    const formattedToDate = new Date(emailId).toLocaleDateString("en-GB"); // Assumes 'emailId' is in the format "YYYY-MM-DD"

    const BaseUrl = process.env.REACT_APP_API_URL;

    const EndPoint = "/riInsurerBulkService/v1/PolicyEnquiry";

    const fetchData = async () => {
      try {
        const response = await axios.post(`${BaseUrl}${EndPoint}`, {
          txnId: "9e2adbbe-39ae-72e8-a8d7-0289147bf001",
          timestamp: "2023-11-20T16:33:25.387994200",
          Source: {
            appType: "WEB",
            osVersion: "11",
            deviceId: "",
            deviceName: "Windows108",
            deviceOS: "Windows",
            appVersion: "1.0",
            clientIp: "0.0.0.0",
            origin: "RI",
          },
          Customer: {
            eiaNo: EiaNo,
            pan: "",
            appName: "",
            mobileNum: "",
            emailId: "",
            policyNo: policyNo,
            policyType: policyType,
          },
        });
        // if (response.ok) {

        const policyresponse = response.data.Policy[getPolicyType(policyType)];
        // console.log(policyresponse, "policyresponse");
        // const bank = policyresponse[0].Bank;
        setResponseArray(policyresponse);

        setBankData(policyresponse[0]);
        setLoading(false);
        setPolicyDetails(response.data["Policy"][getPolicyType(policyType)]);
        // console.log(policyDetails, "policyDetails");
        setValue("Customer");
        setError("");
        // }
      } catch (error) {
        // console.log(error, "--------error");
        setLoading(false);
        setValue("");
        setPolicyDetails(true);
        // setError(error.response.data.errorDescription);
        // alert(error.response.data.errorDescription)
      }
    };

    fetchData();
  };

  function handleReset(e) {
    e.preventDefault();

    // clearing the values
    setEiaNo("");
    setPan("");
    setAppName("");
    setMobileNo("");
    setEmailId("");
    setPolicyNo("");
    setPolicyType("");
    setPolicyDetails(true);
    setShowPolicyDetails(false);
    setLoading(false);
    setValue("");
  }

  const inputAlphaNumericWithSpl = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z-_/\\]+$/gm, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const inputAlphaNumeric = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9A-Z]+$/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value.slice(0, 10).toUpperCase());
    }
  };

  const inputNumeric = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9]+$/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const inputEmail = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9A-Z.@_%+-]+$/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const handleChange = (event, setDate) => {
    const rawDate = event.target.value;
    const formattedDate = formatDate(rawDate);
    setDate(formattedDate);
  };

  const handleExportClick = () => {
    // Export response data as Excel
    exportAsExcel(responseData);
    // console.log("Export Success");
  };

  const handleTab = (event, data) => {
    if (policyDetails != true) {
      setValue(data);
    }
  };

  const exportAsExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "POCList.xlsx");
  };

  const navigate = useNavigate();

  const backbutton = () => {
    navigate("/menudisplaypage");
  };

  const customerDetails = {};

  for (let item in policyDetails[0]) {
    if (
      item !== "Bank" &&
      item !== "Address" &&
      item !== "Contact" &&
      item !== "Nominee" &&
      item !== "claimDetails" &&
      item !== "Appointee" &&
      item !== "insuredMembers" &&
      item !== "VehicleDetails" &&
      item !== "AgentDetails" &&
      item !== "DeductibleDetails"
    ) {
      customerDetails[item] = policyDetails[0][item];
    }
  }

  

  const policyDetailsEnq = {
    array: {},
    insurerDetails: {},
    nomineeDetails: {},
    nomineeDetailsMotor: {},
    insurercommunicetionContactDetails: {},
    Addressvalue: {},
    permanentAddressDetails: {},
    communicationAddressDetails: {},
    bankDetails: {},
    premiumCalculationDetails: {},
    claimDetails: {},
    MotorclaimDetails: {},
    ContactDetails: {},
    VehicleDetails: {},
    AgentDetails: {},
    DeductibleDetails: {},
  };

  if (responseArray) {
    // console.log(responseArray,"<---------------");
    for (let obj of responseArray) {
      for (let item of array) {
        for (let value in obj) {
          if (item === value) {
            policyDetailsEnq["array"][item] = obj[item];
          }
        }
      }

      for (let item of ContactDetails) {
        for (let value in obj.Contact) {
          if (item === value) {
            policyDetailsEnq["ContactDetails"][item] = obj.Contact[item];
          }
        }
      }
      for (let item of Addressvalue) {
        for (let value in obj.Address) {
          if (item === value) {
            policyDetailsEnq["Addressvalue"][item] = obj.Address[item];
          }
        }
      }
      for (let item of bankDetails) {
        for (let value in obj.Bank) {
          if (item === value) {
            policyDetailsEnq["bankDetails"][item] = obj.Bank[item];
          }
        }
      }

      for (let item of communicationAddressDetails) {
        for (let value in obj) {
          if (item === value) {
            policyDetailsEnq["communicationAddressDetails"][item] = obj[item];
          }
        }
      }

      for (let item of permanentAddressDetails) {
        for (let value in obj) {
          if (item === value) {
            policyDetailsEnq["permanentAddressDetails"][item] = obj[item];
          }
        }
      }
// console.log(nomineeDetails,"nomineeDetailsnomineeDetailsnomineeDetails")
      for (let item of nomineeDetails) {
        for (let value in obj.Nominee) {
          for (let a in obj.Nominee[value]) {
            if (item === a) {
              policyDetailsEnq["nomineeDetails"][item] = obj.Nominee[value][a];
            }
          }
        }
      }

      for (let item of nomineeDetailsMotor) {
        for (let value in obj.Nominee) {
          for (let a in obj.Nominee[value]) {
            if (item === a) {
              policyDetailsEnq["nomineeDetails"][item] = obj.Nominee[value][a];
            }
          }
        }
      }

      for (let item of VehicleDetails) {
        for (let value in obj.VehicleDetails) {
          for (let a in obj.VehicleDetails[value]) {
            if (item === a) {
              policyDetailsEnq["VehicleDetails"][item] =
                obj.VehicleDetails[value][a];
            }
          }
        }
      }
      for (let item of AgentDetails) {
        for (let value in obj.AgentDetails) {
          for (let a in obj.AgentDetails[value]) {
            if (item === a) {
              policyDetailsEnq["AgentDetails"][item] =
                obj.AgentDetails[value][a];
            }
          }
        }
      }

      for (let item of DeductibleDetails) {
        for (let value in obj.DeductibleCoverage) {
          for (let a in obj.DeductibleCoverage[value]) {
            if (item === a) {
              policyDetailsEnq["DeductibleDetails"][item] =
                obj.DeductibleCoverage[value][a];
            }
          }
        }
      }

      for (let item of claimDetails) {
        for (let value in obj.claimDetails) {
          for (let a in obj.claimDetails[value]) {
            if (item === a) {
              policyDetailsEnq["claimDetails"][item] =
                obj.claimDetails[value][a];
            }
          }
        }
      }
      for (let item of MotorclaimDetails) {
        for (let value in obj.ClaimDetails) {
          for (let a in obj.ClaimDetails[value]) {
            if (item === a) {
              policyDetailsEnq["MotorclaimDetails"][item] =
                obj.ClaimDetails[value][a];
            }
          }
        }
      }

      for (let item of premiumCalculationDetails) {
        for (let value in obj) {
          if (item === value) {
            policyDetailsEnq["premiumCalculationDetails"][item] = obj[item];
          }
        }
      }

      for (let item of insurerDetails) {
        for (let value in obj.insuredMembers) {
          for (let a in obj.insuredMembers[value]) {
            if (item === a) {
              policyDetailsEnq["insurerDetails"][item] =
                obj.insuredMembers[value][a];
            }
          }
        }
      }

      const policyEnquiryData = {
        ...policyDetailsEnq.array,
        ...policyDetailsEnq.insurerDetails,
        ...policyDetailsEnq.nomineeDetails,
        ...policyDetailsEnq.nomineeDetailsMotor,
        ...policyDetailsEnq.communicationAddressDetails,
        ...policyDetailsEnq.bankDetails,
        ...policyDetailsEnq.ContactDetails,
        ...policyDetailsEnq.premiumCalculationDetails,
        ...policyDetailsEnq.claimDetails,
        ...policyDetailsEnq.VehicleDetails,
        ...policyDetailsEnq.AgentDetails,
        ...policyDetailsEnq.DeductibleDetails,
        ...policyDetailsEnq.Addressvalue,
        ...policyDetailsEnq.MotorclaimDetails,
      };
      const newObject = { ...policyEnquiryData };

      result.push(newObject);
    }
  }
  const [firstVal, setFirstVal] = useState(true);
  const [secondtVal, setSecondVal] = useState(true);
  const [thirdVal, setThirdVal] = useState(true);
  const [indexVal, setIndexVal] = useState();

  const firstBtn = (index) => {
    setFirstVal(!firstVal);
  };

  const secondBtn = () => {
    setSecondVal(!secondtVal);
  };

  const thirdBtn = () => {
    setThirdVal(!thirdVal);
  };

  let cnt = 0;

  // console.log(nomineeDetailsMotor,"nomineeDetailsMotornomineeDetailsMotornomineeDetailsMotornomineeDetailsMotor")

  return (
    <div>
      <Header />
      <div className="MenuPage">
        <Navbar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      </div>
      
        <div className="app-insurer">
        <div>
          <h1 className="heading">
            { ROUTER_URL.POLICY_ENQUIRY}
          </h1>
        </div>

          <div className="row col-12">
            <div className="col-sm-3 col-xs-12 username">
              <div className="mt-2">
                <label className="label_cls">{LABEL_NAME.EIA_NO}</label>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  name="EiaNo"
                  value={EiaNo}
                  onChange={(e) =>
                    inputNumeric(e, (value) => {
                      setEiaNo(value.slice(0, 13));
                    })
                  }
                  className="inputboxvu_insure"
                />
              </div>
            </div>

            <div className="col-sm-3 col-xs-12 username ">
              <div className="mt-2">
                <label className="label_cls">{LABEL_NAME.POLICY_NO}</label>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  name="policyNo"
                  value={policyNo}
                  onChange={(e) =>
                    inputAlphaNumericWithSpl(e, (value) => {
                      setPolicyNo(value);
                    })
                  }
                  required
                  className="inputboxvu_insure"
                />
              </div>
            </div>

            <div className="col-sm-2 col-xs-12 username status-card">
              <div>
                <label className="label_cls">{LABEL_NAME.POLICY_TYPE}</label>
              </div>
              <div style={{ width: "100%" }}>
                <select
                  className="inputboxvu_insure mt-1"
                  value={policyType}
                  onChange={(e) => setPolicyType(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="H">Health</option>
                  <option value="M">Motor</option>
                  <option value="L">Life</option>
                </select>
              </div>
            </div>

            <div className="col-sm-4 col-xs-12 username mt-1">
              <ActionButton
                handleBack={backbutton}
                handleReset={handleReset}
                handleSubmit={handleButtonClick}
              />
            </div>
            <p className="note">Note:{LABEL_NAME.POLICY_ENQUIRY_NOTE}</p>
            {errorMessage && (
              <p className="note" style={{ color: "red" }}>
                {errorMessage}
              </p>
            )}
          </div>
        </div>

        <div className="col-12 row d-flex justify-content-center">
          <div className="col-md-8 ">
            <div className="loading">
              <RotateSpinner size={70} color="#f99117" loading={loading} />
            </div>
            {!loading ? (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleTab}
                  aria-label="wrapped label tabs example"
                  centered
                >
                  <Tab value="Customer" label="Customer Details" />
                  <Tab value="Address" label="Permanent Address" />
                  <Tab value="Bank" label="Bank Details" />
                  <Tab value="Policy" label="Policy Details" />
                </Tabs>
              </Box>
            ) : (
              ""
            )}

            {error && !loading && <p>{error}</p>}

            {policyDetails && !loading && (
              <div>
                {value == "Customer" && policyDetails[0] && (
                  // <p>Customer</p>
                  <TableSection title="" content={customerDetails} />
                )}
                {value == "Address" && policyDetails[0].Address && (
                  <TableSection title="" content={policyDetails[0].Address} />
                )}
                {value == "Bank" && policyDetails[0].Bank && (
                  <TableSection title="" content={policyDetails[0].Bank} />
                )}

                {value == "Policy" &&
                  result &&
                  result.map((item, index) => {
                    cnt = cnt + 1;
                    console.log(item, "itemitem");
                    return (
                      <>
                        {policyType == "H" ? (
                          <div
                            className="mb-2"
                            style={{
                              backgroundColor: "gray",
                              borderRadius: "5px",
                            }}
                          >
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  Policy Details{" "}
                                  {`(${EiaNo} - ${item.policyNo} - ${policyTypeView})`}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Basic Details</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <PolicyDetailsTable
                                      title=""
                                      content={item}
                                      HealthDetails={HealthDetails}
                                    />
                                  </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Address Details</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <AddressDetailsTable
                                      title=""
                                      content={item}
                                      Addressvalue={Addressvalue}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Contact Details</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <ContactDetailsTable
                                      title=""
                                      content={item}
                                      ContactDetails={ContactDetails}
                                    />
                                  </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Nominee Details</Typography>
                                  </AccordionSummary>
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel2-content"
                                      id="panel2-header"
                                    >
                                      <Typography>Nominee</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <NomineeTable
                                        title=""
                                        content={item}
                                        NomineeDetails={NomineeDetails}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                </Accordion>

                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Claim Details</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <ClaimDetailsTable
                                      title=""
                                      content={item}
                                      claimDetails={claimDetails}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>InsuredMembers</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <InsuredMembersTable
                                      title=""
                                      content={item}
                                      InsuredMembers={InsuredMembers}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        ) : (
                          ""
                        )}
                        {policyType == "M" ? (
                          <div
                            className="mb-2"
                            style={{
                              backgroundColor: "gray",
                              borderRadius: "5px",
                            }}
                          >
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <Typography>
                                  Policy Details{" "}
                                  {`(${EiaNo} - ${item.policyNo} - ${policyTypeView})`}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Basic Details</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <PolicyDetailsTable
                                      title=""
                                      content={item}
                                      HealthDetails={HealthDetails}
                                    />
                                  </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Address Details</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <AddressDetailsTable
                                      title=""
                                      content={item}
                                      Addressvalue={Addressvalue}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Contact Details</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <ContactDetailsTable
                                      title=""
                                      content={item}
                                      ContactDetails={ContactDetails}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Nominee Details</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <NomineeTableMotor
                                      title=""
                                      content={item}
                                      nomineeDetailsMotor={nomineeDetailsMotor}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Vehicle Details</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <VehicleTable
                                      title=""
                                      content={item}
                                      VehicleDetails={VehicleDetails}
                                    />
                                  </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Agent Details</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <AgentTable
                                      title=""
                                      content={item}
                                      AgentDetails={AgentDetails}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Deductible Details</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <DeductibleTable
                                      title=""
                                      content={item}
                                      DeductibleDetails={DeductibleDetails}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    <Typography>Claim Details</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <MotorClaimDetailsTable
                                      title=""
                                      content={item}
                                      MotorclaimDetails={MotorclaimDetails}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
     
    </div>
  );
}
export default PolicyEnquiry;
